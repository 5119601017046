import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, CardBody } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import Loader from "../../Helper/Loader";
import empty from "is-empty";
import Select from "react-select";
import Breadcrumbs from "../../Helper/Breadcrumbs";

const SeotagsForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  let propsState = props.history.location.state;

  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let BreadcrumbData = [
    {
      title: "Seotags",
      href: "/seotags",
      state: {
        data: propsState && propsState.data,
      },
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Seotags Details",
      id: "details",
      show: true,
    },
  ]);
  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };
  // const handleSelect = (keyName,setFn,e) =>{
  //     setFn(e);
  //     setForm({
  //         ...form,
  //         [keyName] : (e && e.value) || null
  //     })
  //     if (keyName && !!errors[keyName]) setErrors({
  //         ...errors,
  //         [keyName]: null
  //       })
  // }
  const findErrors = () => {
    const { title } = form;
    const newErrors = {};
    if (empty(title)) newErrors.title = "This is a required field";

    return newErrors;
  };

  const saveForm = () => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? config.service + "/masters/seo_tags/update/" + id + "/"
          : config.service + "/masters/seo_tags/create/",
        data = JSON.parse(JSON.stringify(form));
      data.mandap_id = propsState.data.id;
      axios({
        url: url,
        method: method,
        headers: tokenKey,
        data: data,
      })
        .then((res) => {
          if (editMode) setForm(res.data);
          toast.success("Saved successfully", toastAttr);
          setLoader(false);
          setTimeout(() => {
            renderListView();
          }, 1000);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else toast.error("Saving Failed", toastAttr);
          setLoader(false);
        });
    }
  };

  const renderListView = () => {
    history.push({
      pathname: "/seotags",
      state: {
        data: propsState && propsState.data,
      },
    });
  };
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  useEffect(() => {
    if (propsState && propsState.editMode)
      get(config.service + "/masters/seo_tags/update/" + propsState.id + "/", {
        headers: tokenKey,
      })
        .then((res) => {
          setForm(res.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    setLoader(false);
  }, []);

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={BreadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsdiv">
                <Col md={12}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="formBtnsDiv">
                <Col md={12} style={{ margin: "5px 0" }}></Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              {/* <Row>
                                                <Col md={4}>
                                                    <Form.Label>Name</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="name" value={form.name} onChange={e=> handleInput('name',e.target.value)} isInvalid={!! errors.name} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.name }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row> */}
                              <Row>
                                <Col md={4}>
                                  <Form.Label>Title</Form.Label>
                                </Col>
                                <Col md={7}>
                                  <Form.Control
                                    type="text"
                                    name="title"
                                    value={form.title}
                                    onChange={(e) =>
                                      handleInput("title", e.target.value)
                                    }
                                    isInvalid={!!errors.title}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.title}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <Form.Label>Description</Form.Label>
                                </Col>
                                <Col md={7}>
                                  <Form.Control
                                    type="text"
                                    as="textarea"
                                    name="description"
                                    value={form.description}
                                    onChange={(e) =>
                                      handleInput("description", e.target.value)
                                    }
                                    isInvalid={!!errors.description}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.description}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>
                              {/* <Row>
                                                <Col md={4}>
                                                    <Form.Label>Canonical</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="canonical" value={form.canonical} onChange={e=> handleInput('canonical',e.target.value)} isInvalid={!! errors.canonical} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.canonical }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row> */}
                              {/* <Row>
                                                <Col md={4}>
                                                    <Form.Label>H1</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="h1" value={form.h1} onChange={e=> handleInput('h1',e.target.value)} isInvalid={!! errors.h1} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.h1 }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>H2</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="h2" value={form.h2} onChange={e=> handleInput('h2',e.target.value)} isInvalid={!! errors.h2} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.h2 }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row> */}
                              <Row>
                                <Col md={4}>
                                  <Form.Label>Kws</Form.Label>
                                </Col>
                                <Col md={7}>
                                  <Form.Control
                                    type="text"
                                    as="textarea"
                                    name="kws"
                                    value={form.kws}
                                    onChange={(e) =>
                                      handleInput("kws", e.target.value)
                                    }
                                    isInvalid={!!errors.kws}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.kws}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default SeotagsForm;

import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FaTypo3 } from "react-icons/fa";
import { HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import Search from "../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FiEdit2 } from "react-icons/fi";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import Loader from "../../Helper/Loader";
import { over } from "lodash";
import { DropdownButton, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { TextField, Popover, Typography } from "@mui/material";
import empty from "is-empty";
import { saveAs } from "file-saver";

const EnquiryBook = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let propsState = props.location.state;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Enquiry",
      href: "#",
    },
  ];
  let mapSource = {
      affiliated: "Affliated",
      online: "Online",
      admin: "Admin",
    },
    mapStatus = {
      awaiting_booking: "Booking Awaiting",
      idea_dropped: "Idea Dropped",
      fixed_mandap: "Mandap Fixed",
    };
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [selectedValue, setSelectedValue] = useState("enquiry");
  const [error, setError] = useState({});
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [fromdate, setfromdate] = useState("");
  const handlefromDateChange = (date) => {
    // setSelectedDate(date);
    setfromdate(date);
  };
  const [todate, settodate] = useState("");
  const handletoDateChange = (date) => {
    settodate(date);
  };
  const [searchname, setsearchname] = useState("");
  const handleSearch = (search) => {
    setsearchname(search);
  };
  const exportdata = (event) => {
    if (!empty(fromdate)) {
      var fromdate1;
      fromdate1 = fromdate.toLocaleDateString();
      if (!todate) {
        alert("select date range");
        return;
      }
    }
    if (!empty(todate)) {
      var todate1;
      todate1 = todate.toLocaleDateString();
      if (!fromdate) {
        alert("select date range");
        return;
      }
    }
    fetch(config.service + "/masters/enqiryexcelreport/", {
      method: "POST",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("tokenKey"),
        "Content-Type": "application/json",
        // 'X-CSRFToken': getCookie('csrftoken')
      },
      body: JSON.stringify({
        fromdate: fromdate1,
        todate: todate1,
        selectedValue: selectedValue,
        searchname: searchname,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, "example.xlsx"))
      .catch((error) => setError(error));
  };

  const renderOnSearch = (searchTerm) => {
    var search = searchTerm;
    setsearchname(search);
    if (!empty(fromdate)) {
      var fromdate1;
      fromdate1 = fromdate.toLocaleDateString();
    }
    if (!empty(todate)) {
      var todate1;
      todate1 = todate.toLocaleDateString();
    }
    fetch(config.service + "/masters/enqirysearch/", {
      method: "POST",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("tokenKey"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        search: search,
        searchtype: selectedValue,
        fromdate: fromdate1,
        todate: todate1,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        for (let i in data) {
          data[i].created_on = data[i].date_of_booking;
        }
        settableData(data);
      })
      .catch((error) => setError(error));
  };
  useEffect(() => {
    get(config.service + "/customer/enquiry/list/", tokenKey)
      .then((res) => {
        settableData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
    setLoader(false);
  }, []);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: tableData.length,
  });

  const cols = [
    {
      dataField: "mandap_name",
      text: "Mandap Name",
      headerStyle: () => {
        return { width: "300px" };
      },
      formatter: (cell, row) => (
        <div style={{ width: "300px", fontSize: "14px" }}>{cell}</div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },
    {
      dataField: "name",
      text: "Customer Name",
      headerStyle: () => {
        return { width: "300px" };
      },
      formatter: (cell, row) => (
        <div style={{ width: "300px", fontSize: "14px" }}>{cell}</div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },
    {
      dataField: "mobile",
      text: "Mobile",
      headerStyle: () => {
        return { width: "300px" };
      },
    },
    {
      dataField: "email",
      text: "Email ID",
      headerStyle: () => {
        return { width: "300px" };
      },
      formatter: (cell, row) => (
        <div style={{ width: "300px", fontSize: "14px" }}>{cell}</div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },
    {
      dataField: "date_from",
      text: "From Date",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
      formatter: (cell, row) => {
        let fromDate = row.date_from
          ? row.date_from.replace("T", " ").replace("Z", "")
          : "";
        return <div>{fromDate.slice(0, 10)}</div>;
      },
    },
    {
      dataField: "date_to",
      text: "To Date",
      headerStyle: () => {
        return { minWidth: "120px" };
      },
      formatter: (cell, row) => {
        let toDate = row.date_to
          ? row.date_to.replace("T", " ").replace("Z", "")
          : "";
        return <div>{toDate.slice(0, 10)}</div>;
      },
    },
    {
      text: "Date of Activity",
      dataField: "date_of_booking",
      headerStyle: () => {
        return { width: "180px" };
      },
      formatter: (cell, row) => {
        let dateOfBooking = row.created_on
          ? row.created_on.replace("T", " ").replace("Z", "")
          : "";
        return <div>{dateOfBooking.slice(0, 10)}</div>;
      },
    },
    {
      dataField: "enquiry_no",
      text: "Enquiry Number",
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "booking_no",
      text: "Booking Reference Number",
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "paid_amount",
      text: "Paid Amount",
      headerStyle: () => {
        return { width: "150px" };
      },
    },
  ];

  const callenquiry = () => {
    get(config.service + "/customer/enquiry/list/", tokenKey)
      .then((res) => {
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
  };

  const callbooking = () => {
    get(config.service + "/masters/bookinglist/", tokenKey)
      .then((res) => {
        for (let i in res.data) {
          res.data[i].created_on = res.data[i].date_of_booking;
        }
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    if (fromdate && todate) {
      if (!empty(fromdate)) {
        var fromdate1;
        fromdate1 = fromdate.toLocaleDateString();
      }
      if (!empty(todate)) {
        var todate1;
        todate1 = todate.toLocaleDateString();
      }
      fetch(config.service + "/masters/enqiryreport/", {
        method: "POST",
        headers: {
          Authorization: "Token " + sessionStorage.getItem("tokenKey"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fromdate: fromdate1,
          todate: todate1,
          selectedValue: selectedValue,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          for (let i in data) {
            data[i].created_on = data[i].date_of_booking;
          }
          // data.created_on = data.date_of_booking
          settableData(data);
          // let dat = []
          // let data1 = dat.push(data)
          // settableData(dat)
        })
        .catch((error) => setError(error));
    } else {
      alert("please select date range");
    }
  };

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
    // Call your API function here with the selected value
    let value = e.target.value;
    if (value === "enquiry") {
      callenquiry();
    } else if (value === "booking") {
      callbooking();
    } else {
      // do nothing
    }
  };

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={2}>
                  <select
                    style={{
                      display: "inline-block",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      padding: "5px 5px",
                      borderRadius: "0px",
                    }}
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <option value="enquiry">Enquiry</option>
                    <option value="booking">Booking</option>
                  </select>
                </Col>
                <Col md={2}>
                  <DatePicker
                    selected={fromdate}
                    onChange={handlefromDateChange}
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    placeholderText="From date"
                  />
                </Col>
                <Col md={2}>
                  <DatePicker
                    selected={todate}
                    onChange={handletoDateChange}
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    placeholderText="To date"
                  />
                </Col>
                <Col md={1}>
                  <button className="btn btn-success" onClick={handleSubmit}>
                    Search
                  </button>
                </Col>
                <Col md={4}>
                  <Search
                    callbackFn={(e) => renderOnSearch(e)}
                    value={searchname}
                    onChange={handleSearch}
                  />
                </Col>
                <Col md={1}>
                  <button className="btn btn-success" onClick={exportdata}>
                    Export
                  </button>
                </Col>
              </Row>
              <Loader loading={loading} overlay={overlay}>
                <Row className="vendorList">
                  <BootstrapTable
                    bootstrap4
                    wrapperClasses="table-responsive pad-20"
                    data={tableData}
                    columns={cols}
                    keyField="id"
                    containerStyle={{ width: "100%" }}
                    hover
                    noDataIndication="No data found"
                    pagination={options}
                  />
                </Row>
              </Loader>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};
export default EnquiryBook;

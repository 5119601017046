import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import CustomBackdrop from "../../../../shared/BackDropLoader";
import Search from "../../../Helper/CustomSearch";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import axios, { get } from "axios";
import { CustomGrid } from "../../../../shared/CustomGrid";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import { CustomPagination } from "../../Pagination";
import { uniq } from "lodash";

const AddUsers = () => {
  const [rowData, setRowData] = useState();
  const [backDropLoader, setBackGropLoader] = useState(false);
  const [getSelectedDataDetails, setGetSelecteDataDetails] = useState([]);
  const [getPageSelectedDataDetails, setGetPageSelecteDataDetails] = useState(
    []
  );

  const [paginationCount, setPageNationCount] = useState(1);
  const [serchFilterPagination, setSearchFilterPagination] = useState(1);
  const [searchTerm, setSearchTerm] = useState(false);
  const [paginationClicked, setPaginationClicked] = useState(false);
  const [getSearchValues, setGetSearchValues] = useState();
  const [buttoncreate, setButtonCreate] = useState(true);
  const [datasend, setDataSend] = useState(false);
  const [uncheckedData, setUncheckedData] = useState([]);
  // useEffect(()=>{
  //   const sessData=sessionStorage.getItem

  // },[paginationClicked])

  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };

  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "Add Users",
      href: "#",
    },
  ];

  useEffect(() => {
    paginationCount > 1 ? setBackGropLoader(true) : setBackGropLoader(false);
    if (searchTerm === false) {
      get(
        config.service + `/masters/unassigned-vendors/?page=${paginationCount}`,
        { headers: tokenKey }
      )
        .then((response) => {
          setButtonCreate(false);
          setBackGropLoader(true);
          setRowData(response.data);
          setBackGropLoader(false);
        })
        .catch((err) => {
          setBackGropLoader(false);
        });
    } else {
      setBackGropLoader(true);
      get(
        config.service +
          `/masters/unassigned-vendors/?page=${serchFilterPagination}&search=${getSearchValues}`,
        { headers: tokenKey }
      )
        .then((response) => {
          setButtonCreate(false);
          setRowData(response.data);
          setBackGropLoader(false);
        })
        .catch((err) => {
          setBackGropLoader(false);
        });
    }
  }, [paginationCount, serchFilterPagination, datasend]);

  const renderOnSearch = (searchTerm) => {
    setGetSearchValues(searchTerm);
    setBackGropLoader(true);
    setSearchTerm(true);
    setButtonCreate(false);
    get(
      config.service +
        `/masters/unassigned-vendors/?page=${serchFilterPagination}&search=${searchTerm}`,
      { headers: tokenKey }
    )
      .then((response) => {
        setButtonCreate(false);
        setRowData(response.data);
        setBackGropLoader(false);
      })
      .catch((err) => {
        setBackGropLoader(false);
      });
  };

  const columnDefs = [
    {
      headerName: "ID",
      field: "id",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      filter: true,
      width: "100px",
      resizable: true,
    },
    {
      headerName: "Vendor Name",
      field: "vendor_name",
      filter: true,
      resizable: true,
      width: 300,
    },
    {
      headerName: "Phone Number",
      field: "owner_phone",
      filter: true,
      resizable: true,
    },
    {
      headerName: "Owner Name",
      field: "owner_name",
      filter: true,
      resizable: true,
    },
    {
      headerName: "City",
      field: "city_name.name",
      resizable: true,
      filter: true,
    },
    {
      headerName: "Address",
      field: "address_1",
      resizable: true,
      filter: true,
      width: 500,
    },
    {
      headerName: "State",
      field: "city_name.state_name",
      resizable: true,
      filter: true,
    },
  ];

  // ______________ GET PAGE COUNT _______________//
  // useEffect(() => {
  //   if (sessionStorage.getItem("checked") !== null || undefined) {
  //     var roeSelected = sessionStorage.getItem("checked")
  //       ? sessionStorage.getItem("checked")
  //       : [];
  //     console.log("roeSelected", roeSelected);

  //     if (roeSelected.length > 0) {
  //       const rowSelectedData = roeSelected ? roeSelected.split(",") : [];
  //       console.log("rowSelectedData0", rowSelectedData);
  //       const mapdata = rowSelectedData?.map((j) => Number(j));
  //       console.log("mapdata", mapdata);
  //       setGetPageSelecteDataDetails(mapdata);
  //     }
  //   }
  // }, [paginationClicked]);

  const handleGetPageCount = (pageCount) => {
    setPaginationClicked(!paginationClicked);
    if (searchTerm === false) {
      setPageNationCount(pageCount);
    } else {
      setSearchFilterPagination(pageCount);
    }
    const result = [];
    const res2 = [];

    const nonDuplicate = uniq(getSelectedDataDetails) || null;

    if (
      (sessionStorage.getItem("checked") !== null || undefined) &&
      (nonDuplicate !== null || undefined)
    ) {
      for (let i = 0; i <= nonDuplicate?.length - 1; i++) {
        if (
          nonDuplicate[i] >= rowData?.results[0]?.id &&
          nonDuplicate[i] <= rowData?.results[rowData?.results?.length - 1]?.id
        ) {
          result.push(nonDuplicate[i]);
        } else {
          res2.push(nonDuplicate[i]);
        }
      }

      if (nonDuplicate !== null || undefined) {
        const testdifference = result.filter((x) => !uncheckedData.includes(x));
        if (testdifference.length > 0) {
          const finfalData = [...testdifference, ...res2];
          sessionStorage.removeItem("checked");
          sessionStorage.setItem("checked", finfalData);
          setGetSelecteDataDetails(finfalData);
        } else {
          sessionStorage.removeItem("checked");
          sessionStorage.setItem("checked", res2);
          setGetSelecteDataDetails(res2);
        }
      }
    } else if (nonDuplicate !== null) {
      sessionStorage.setItem("checked", nonDuplicate);
    }
  };

  //_____________GET SELECTED CHECKBOX VALUES_____________//
  const handleSelectedCheckBoxValues = (data, unchecked) => {
    const getSeletedValues = data?.map(({ id }) => id);
    const getUncheckdData = unchecked?.map(({ id }) => id);

    const uniqValue = uniq(getSeletedValues) || null;

    if (uniqValue !== null) {
      setGetPageSelecteDataDetails(uniqValue);
    }
    setUncheckedData(getUncheckdData);

    setGetSelecteDataDetails((prevSelectedValues) => [
      ...prevSelectedValues,
      ...getSeletedValues,
    ]);
  };

  // ___________________________POST DATA FOR SELECTED VALUES__________________________ //

  const handlecCreateUsers = async () => {
    setBackGropLoader(true);

    const result = [];
    const res2 = [];
    let finalArray = [];

    const nonDuplicate = uniq(getSelectedDataDetails) || null;

    if (
      (sessionStorage.getItem("checked") !== null || undefined) &&
      (nonDuplicate !== null || undefined)
    ) {
      for (let i = 0; i <= nonDuplicate?.length - 1; i++) {
        if (
          nonDuplicate[i] >= rowData?.results[0]?.id &&
          nonDuplicate[i] <= rowData?.results[rowData?.results?.length - 1]?.id
        ) {
          result.push(nonDuplicate[i]);
        } else {
          res2.push(nonDuplicate[i]);
        }
      }

      if (nonDuplicate !== null || undefined) {
        const testdifference = result.filter((x) => !uncheckedData.includes(x));
        if (testdifference.length > 0) {
          const finfalData = [...testdifference, ...res2];
          finalArray = finfalData;
          sessionStorage.removeItem("checked");
          sessionStorage.setItem("checked", finfalData);
          setGetSelecteDataDetails(finfalData);
        } else {
          finalArray = res2;
          sessionStorage.removeItem("checked");
          sessionStorage.setItem("checked", res2);
          setGetSelecteDataDetails(res2);
        }
      }
    } else if (nonDuplicate !== null) {
      finalArray = nonDuplicate;
      sessionStorage.setItem("checked", nonDuplicate);
    }

    await axios
      .post(
        config.service + "/accounts/user/bulk_create/",
        JSON.parse(JSON.stringify(finalArray)),
        { headers: tokenKey }
      )
      .then((response) => {
        setBackGropLoader(false);
        toast.success(response?.data?.data);
        setDataSend(true);
        sessionStorage.removeItem("checked");
        setGetSelecteDataDetails([]);
        setPageNationCount(1);
        setTimeout(() => {
          window.location.reload();
        }, 7000);
      })
      .catch((err) => {
        const errData = err?.request?.response
          ? JSON.parse(err?.request?.response)
          : "";
        let data;
        if (errData !== "") {
          data = Object.values(errData);
        }
        const errmessage =
          data?.length > 1
            ? data[0] + "-" + data?.[1]?.vendor_name
            : "Data are not created";

        setBackGropLoader(false);
        toast.error(`${errmessage}`);
      });
  };

  const handleCloseBackdrop = () => {
    setBackGropLoader(false);
  };

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}>
                  <Button
                    color="primary"
                    disabled={
                      buttoncreate || getSelectedDataDetails?.length === 0
                    }
                    onClick={(e) => handlecCreateUsers()}
                  >
                    Create
                  </Button>
                </Col>
                <Col md={4}>
                  <Search
                    callbackFn={(e) => renderOnSearch(e)}
                    disabled={buttoncreate}
                  />
                </Col>
              </Row>

              <div style={{ paddingTop: "10px" }}>
                <CustomGrid
                  rowData={rowData?.results}
                  columnData={columnDefs}
                  getSelectedValues={handleSelectedCheckBoxValues}
                  tableHeight={450}
                  paginationClicked={paginationClicked}
                />
              </div>
              <div
                style={{
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomPagination
                  totlaCount={Math.ceil(rowData?.count / 10)}
                  getCurrentPageCount={handleGetPageCount}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CustomBackdrop
        openBackDrop={backDropLoader}
        closeBackDrop={handleCloseBackdrop}
      />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default AddUsers;

import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, CardBody } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import Loader from "../../Helper/Loader";
import empty from "is-empty";
import Select from "react-select";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const EnquiryForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  let propsState = props.history.location.state;
  let sourceTypes = [
    { label: "Affliated", value: "affiliated" },
    { label: "Online", value: "online" },
    { label: "Admin", value: "admin" },
  ];
  let statusList = [
    { label: "Booking Awaiting", value: "awaiting_booking" },
    { label: "Idea Dropped", value: "idea_dropped" },
    { label: "Mandap Fixed", value: "fixed_mandap" },
    { label: "Others", value: "others" },
  ];
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [mandapList, setmandapList] = useState([]);
  const [mandap, setMandap] = useState(null);
  const [source, setsource] = useState(null);
  const [status, setstatus] = useState(null);
  const [funcTypeList, setfuncTypeList] = useState([]);
  const [funcType, setfuncType] = useState(null);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let BreadcrumbData = [
    {
      title: "Enquiry",
      href: "/enquiry",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Details",
      id: "details",
      show: true,
    },
  ]);
  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };
  const handleSelect = (keyName, setFn, e) => {
    setFn(e);
    setForm({
      ...form,
      [keyName]: (e && e.value) || null,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };
  const findErrors = () => {
    const {
      budget,
      name,
      mobile,
      email,
      enquiry_source,
      enquiry_status,
      date_from,
      date_to,
      other_status,
      enquiry_function_type,
    } = form;
    const newErrors = {};
    if (empty(mandap)) newErrors.mandap = "This is a required field";
    if (empty(enquiry_function_type))
      newErrors.enquiry_function_type = "This is a required field";
    if (empty(budget)) newErrors.budget = "This is a required field";
    if (empty(name)) newErrors.name = "This is a required field";
    if (empty(mobile)) newErrors.mobile = "This is a required field";
    if (empty(email)) newErrors.email = "This is a required field";
    if (empty(enquiry_source))
      newErrors.enquiry_source = "This is a required field";
    if (empty(enquiry_status))
      newErrors.enquiry_status = "This is a required field";
    if (empty(date_from)) newErrors.date_from = "This is a required field";
    if (empty(date_to)) newErrors.date_to = "This is a required field";
    if (status && status.value === "others" && empty(other_status))
      newErrors.other_status = "This is a required field";

    return newErrors;
  };

  const saveForm = () => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? config.service + "/customer/enquiry/update/"
          : config.service + "/customer/enquiry/create/",
        data = JSON.parse(JSON.stringify(form));
      if (editMode) data.enquiry_id = form.id;
      if (data.date_from)
        data.date_from = moment(form.date_from).format("YYYY-MM-DD HH:mm:ss");
      if (data.date_to)
        data.date_to = moment(form.date_to).format("YYYY-MM-DD HH:mm:ss");
      axios({
        url: url,
        method: method,
        headers: tokenKey,
        data: data,
      })
        .then((res) => {
          if (res.data.date_from && res.data.date_to) {
            let fromDate = res.data.date_from
                .replace("T", " ")
                .replace("Z", ""),
              toDate = res.data.date_to.replace("T", " ").replace("Z", "");
            res.data.date_from = new Date(fromDate);
            res.data.date_to = new Date(toDate);
          }
          if (editMode) setForm(res.data);
          toast.success("Saved successfully", toastAttr);
          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else toast.error("Saving Failed", toastAttr);
          setLoader(false);
        });
    }
  };

  const renderListView = () => {
    history.push("/enquiry");
  };
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const verifyLead = () => {
    if (empty(form.pincode) || empty(form.enquiry_function_type)) {
      toast.error(
        "Enter Address/Pincode and Function Type to proceed",
        toastAttr
      );
    } else {
      let data = {
        booking_id: null,
        pincode: form.pincode,
        enquiry_id: form.id,
        enquiry_function_type: form.enquiry_function_type,
      };
      setLoader(true);
      post(config.service + "/kalyan-uat/export-lead-data/", data, {
        headers: tokenKey,
      })
        .then((res) => {
          toast.success("Verified Successfully", toastAttr);
          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else toast.error("Verification Failed", toastAttr);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    get(config.service + "/masters/base/vendor-mandap/", { headers: tokenKey })
      .then((res) => {
        let mandap = [],
          type = [];
        res.data.map((obj) => {
          mandap.push({ value: obj.id, label: obj.mandap_name });
        });
        setmandapList(mandap);
        get(config.service + "/masters/mandapam/function-type/", {
          headers: tokenKey,
        }).then((res) => {
          res.data.map((obj) => {
            type.push({ value: obj.id, label: obj.function_type });
          });
          setfuncTypeList(type);
          if (propsState && propsState.editMode)
            get(config.service + "/customer/enquiry/" + propsState.id + "/", {
              headers: tokenKey,
            }).then((res) => {
              if (res.data.date_from && res.data.date_to) {
                let fromDate = res.data.date_from.slice(0, 19),
                  toDate = res.data.date_to.slice(0, 19);
                res.data.date_from = fromDate;
                res.data.date_to = toDate;
              }
              let mandapVal = mandap.filter(
                (temp) => temp.value === res.data.mandap_id
              );
              let sourceVal = sourceTypes.filter(
                (temp) => temp.value === res.data.enquiry_source
              );
              let statusVal = statusList.filter(
                (temp) => temp.value === res.data.enquiry_status
              );
              let typeVal = type.filter(
                (temp) => temp.value === res.data.enquiry_function_type
              );
              setMandap(mandapVal[0]);
              setsource(sourceVal[0]);
              setstatus(statusVal[0]);
              setfuncType(typeVal[0]);
              setForm(res.data);
              setLoader(false);
            });
          else setLoader(false);
        });
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  const onChangeDateTime = (value, keyName) => {
    let val = moment(value).format("YYYY-MM-DD HH:mm:ss");
    setForm({
      ...form,
      [keyName]: val,
    });
    setErrors({
      ...errors,
      [keyName]: null,
    });
  };

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={BreadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsdiv">
                <Col md={12}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                  {editMode ? (
                    <Button variant="primary" onClick={verifyLead}>
                      Push to ILEAD
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className="formBtnsDiv">
                <Col md={12} style={{ margin: "5px 0" }}></Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              <fieldset className="border p-2 fieldsetCls">
                                <legend className="w-auto">
                                  Enquiry Details
                                </legend>
                                <Row>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Mandap</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.mandap
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={mandap}
                                          onChange={(e) =>
                                            handleSelect(
                                              "mandap_id",
                                              setMandap,
                                              e
                                            )
                                          }
                                          options={mandapList}
                                          isClearable
                                        />
                                        {errors.mandap ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.mandap}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Function Type</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.enquiry_function_type
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={funcType}
                                          onChange={(e) =>
                                            handleSelect(
                                              "enquiry_function_type",
                                              setfuncType,
                                              e
                                            )
                                          }
                                          options={funcTypeList}
                                          isClearable
                                        />
                                        {errors.enquiry_function_type ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.enquiry_function_type}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Enquiry Source</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.enquiry_source
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={source}
                                          onChange={(e) =>
                                            handleSelect(
                                              "enquiry_source",
                                              setsource,
                                              e
                                            )
                                          }
                                          options={sourceTypes}
                                          isClearable
                                        />
                                        {errors.enquiry_source ? (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.enquiry_source}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>
                                          Enquiry Feedback
                                        </Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.enquiry_status
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={status}
                                          onChange={(e) =>
                                            handleSelect(
                                              "enquiry_status",
                                              setstatus,
                                              e
                                            )
                                          }
                                          options={statusList}
                                          isClearable
                                        />
                                        {errors.enquiry_status ? (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.enquiry_status}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    {status && status.value === "others" ? (
                                      <Row>
                                        <Col md={4}>
                                          <Form.Label>
                                            Other Feedback
                                          </Form.Label>
                                        </Col>
                                        <Col md={7}>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={form.other_status}
                                            onChange={(e) =>
                                              handleInput(
                                                "other_status",
                                                e.target.value
                                              )
                                            }
                                            isInvalid={!!errors.other_status}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.other_status}
                                          </Form.Control.Feedback>
                                        </Col>
                                      </Row>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>From Date</Form.Label>
                                      </Col>
                                      {form.enquiry_source === "online" ? (
                                        <Col md={7} className="dateTimePicker">
                                          <Form.Control
                                            value={form.date_from}
                                            disabled
                                          />
                                        </Col>
                                      ) : (
                                        <Col md={7} className="dateTimePicker">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DateTimePicker
                                              label=""
                                              value={form.date_from || null}
                                              onChange={(newValue) =>
                                                onChangeDateTime(
                                                  newValue,
                                                  "date_from"
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  className="form-control"
                                                  {...params}
                                                />
                                              )}
                                            />
                                            {errors.date_from ? (
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="d-block"
                                              >
                                                {errors.date_from}
                                              </Form.Control.Feedback>
                                            ) : (
                                              ""
                                            )}
                                          </LocalizationProvider>
                                        </Col>
                                      )}
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>To Date</Form.Label>
                                      </Col>
                                      {form.enquiry_source === "online" ? (
                                        <Col md={7} className="dateTimePicker">
                                          <Form.Control
                                            value={form.date_to}
                                            disabled
                                          />
                                        </Col>
                                      ) : (
                                        <Col md={7} className="dateTimePicker">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DateTimePicker
                                              label=""
                                              value={form.date_to || null}
                                              onChange={(newValue) =>
                                                onChangeDateTime(
                                                  newValue,
                                                  "date_to"
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  className="form-control"
                                                  {...params}
                                                />
                                              )}
                                            />
                                            {errors.date_to ? (
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="d-block"
                                              >
                                                {errors.date_to}
                                              </Form.Control.Feedback>
                                            ) : (
                                              ""
                                            )}
                                          </LocalizationProvider>
                                        </Col>
                                      )}
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Budget</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="number"
                                          name="name"
                                          value={form.budget}
                                          onChange={(e) =>
                                            handleInput(
                                              "budget",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.budget}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.budget}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </fieldset>
                              <fieldset className="border p-2 fieldsetCls">
                                <legend className="w-auto">
                                  Contact Details
                                </legend>
                                <Row>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Name</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          value={form.name}
                                          onChange={(e) =>
                                            handleInput("name", e.target.value)
                                          }
                                          isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.name}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Mobile</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="mobile"
                                          value={form.mobile}
                                          onChange={(e) =>
                                            handleInput(
                                              "mobile",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.mobile}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.mobile}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Email</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="email"
                                          name="email"
                                          value={form.email}
                                          onChange={(e) =>
                                            handleInput("email", e.target.value)
                                          }
                                          isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.email}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Address</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          as="textarea"
                                          name="address_line_1"
                                          value={form.address_line_1}
                                          onChange={(e) =>
                                            handleInput(
                                              "address_line_1",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Pincode</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="number"
                                          name="pincode"
                                          value={form.pincode}
                                          onChange={(e) =>
                                            handleInput(
                                              "pincode",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Label>Other details</Form.Label>
                                  </Col>
                                  <Col md={9}>
                                    <Form.Control
                                      as="textarea"
                                      name="other_details"
                                      value={form.other_details}
                                      onChange={(e) =>
                                        handleInput(
                                          "other_details",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.other_details}
                                    />
                                  </Col>
                                </Row>
                              </fieldset>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default EnquiryForm;

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import CustomBackdrop from "../../../../shared/BackDropLoader";
import { ToastContainer, toast } from "react-toastify";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import config from "../../../../config";
import axios, { get } from "axios";
import { CustomClientSideGrid } from "../../../../shared/ClientSidePaginationGrid";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import { cloneDeep } from "lodash";
import Modal from "@mui/material/Modal";

import AddModel from "./AddModel";
import DeleteModel from "./DeleteModal";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";

const Discounts = () => {
  const [rowData, setRowData] = useState("");
  const [backDropLoader, setBackGropLoader] = useState(false);
  const [getSelectedDataDetails, setGetSelecteDataDetails] = useState([]);
  const [resultData, setResultData] = useState(null);
  const [removedData, setRemovedData] = useState([]);
  const [buttoncreate, setButtonCreate] = useState(true);
  const [datasend, setDataSend] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [filteredRowsNum, setFilteredRowNum] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [openRemove, setOpenRemove] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setOpenRemove(false);
  };

  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };

  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "Discounts",
      href: "#",
    },
  ];

  useEffect(() => {
    get(config.service + `/masters/guest/all_data_vendor_mandap/`, {
      headers: tokenKey,
    })
      .then((response) => {
        const newArray = cloneDeep(response.data);
        setResultData(newArray);
        setRowData(response.data);
        setButtonCreate(false);
        //   setBackGropLoader(true);
        setBackGropLoader(false);
      })
      .catch((err) => {
        setBackGropLoader(false);
      });
  }, [datasend]);

  const TotalValueRenderer = (props) => {
    const checkedHandler = (event) => {
      let checked = event.target.checked;
      const propsData = [props.data];
      setRemovedData((prevSelectedValues) => [
        ...prevSelectedValues,
        ...propsData,
      ]);
      const tempArr = resultData;
      const index = tempArr.findIndex((x) => x.id === props.data.id);
      tempArr[index].status = !tempArr[index].status;
      setResultData(tempArr);
      let colId = props?.column?.getId();

      props.node.setDataValue(`${colId}`, checked);
    };
    return (
      <FormGroup>
        <FormControlLabel
          control={<Switch />}
          label={props.value === true ? "Active" : "Inactive"}
          onChange={(e) => checkedHandler(e)}
          checked={props.value}
          disabled={props.value === false}
          sx={{
            "& .MuiFormControlLabel-label.Mui-disabled": {
              color: "#000000",
            },
          }}
        />
      </FormGroup>
    );
  };
  const columnDefs = [
    {
      headerName: "ID",
      field: "id",
      // headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      filter: true,
      width: "100px",
      headerCheckboxSelectionFilteredOnly: true,

      headerCheckboxSelection: (params) => {
        const displayedColumns = params.api.getAllDisplayedColumns();

        return displayedColumns[0] === params.column;
      },
      resizable: true,
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
        defaultOption: "equals",
      },
    },
    {
      headerName: "Venue Name",
      field: "mandap_name",
      filter: true,
      resizable: true,
      width: 300,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "City",
      field: "city_name",
      filter: true,
      resizable: true,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "Venue Type",
      field: "mandap_type_name",
      filter: true,
      width: 180,
      resizable: true,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "Discount Status",
      field: "has_discount",
      resizable: true,
      filter: true,
      width: 180,
      cellRenderer: (params) => {
        return params?.value === true ? "Available" : "UnAvailable";
      },
      // cellRenderer: TotalValueRenderer,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },

    {
      headerName: "Discount(%)",
      field: "discount_percent",
      resizable: true,
      filter: true,
      width: 150,
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
        defaultOption: "equals",
      },
    },
    {
      headerName: "Address",
      field: "address_1",

      resizable: true,
      filter: true,
      width: 400,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
  ];
  //_____________GET SELECTED CHECKBOX VALUES_____________//
  const handleSelectedCheckBoxValues = (data, unchecked) => {
    const getSeletedValues = data;
    setGetSelecteDataDetails(getSeletedValues);
  };

  // ___________________________POST DATA FOR SELECTED VALUES__________________________ //

  const handlecCreateUsers = () => {
    setOpen(true);
  };
  const handleRemoveUser = () => {
    setOpenRemove(true);
  };

  const handleCloseBackdrop = () => {
    setBackGropLoader(false);
  };
  const handleRemoveDiscount = () => {
    const Data = removedData?.map((j) => j.id);
    setBackGropLoader(true);
    axios
      .put(config.service + "/masters/mandap_discount_bulk_remove/", Data, {
        headers: tokenKey,
      })
      .then(function (response) {
        setBackGropLoader(false);
        toast.success("Mandapam  Discount Removed ");
        handleClose();
        setDataSend(true);
      })
      .catch(function (error) {
        setBackGropLoader(false);
        toast.error("Cant Be Removed");
      });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const handleGridAPI = (params) => {
    setGridApi(params);
  };
  useEffect(() => {
    if (rowData !== "") {
      setFilteredRowNum(rowData?.length);
    }
  }, [rowData]);
  const handleFilteredRows = (params) => {
    setFilteredRowNum(params);
  };
  const handleClear = () => {
    gridApi.deselectAll();
    gridApi.setFilterModel(null);
  };
  const fetchData = async () => {
    handleClear();
    setBackGropLoader(true);
    get(config.service + `/masters/guest/all_data_vendor_mandap/`, {
      headers: tokenKey,
    })
      .then((response) => {
        const newArray = cloneDeep(response.data);
        setResultData(newArray);
        setRowData(response.data);
        setButtonCreate(false);

        setBackGropLoader(false);
      })
      .catch((err) => {
        setBackGropLoader(false);
      });
  };

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={2}>
                  <Button
                    color="primary"
                    disabled={
                      buttoncreate || getSelectedDataDetails?.length === 0
                    }
                    onClick={(e) => handlecCreateUsers()}
                  >
                    Add Discounts
                  </Button>
                </Col>
                <Col md={4}>
                  <Button
                    color="primary"
                    disabled={getSelectedDataDetails?.length === 0}
                    onClick={() => handleRemoveUser()}
                  >
                    Remove Discounts
                  </Button>
                </Col>
                <Col md={3} style={{ marginTop: "7px" }}>
                  Total Number of Records: {filteredRowsNum}
                </Col>
                <Col md={3} style={{ marginTop: "7px" }}>
                  Total Number of Records Selected:{" "}
                  {getSelectedDataDetails?.length}
                </Col>
              </Row>
              <Row>
                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "500px" }}
                >
                  <CustomClientSideGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    tableHeight={"500px"}
                    getSelectedValues={handleSelectedCheckBoxValues}
                    getGridAPI={handleGridAPI}
                    getFilteredRows={handleFilteredRows}
                  />
                </div>
              </Row>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                disableBackdropClick
              >
                <Box sx={{ ...style, width: 1200, minHeight: 500, padding: 2 }}>
                  <h3 id="child-modal-title">Add Discounts</h3>
                  <div style={{ padding: 2 }}>
                    <AddModel
                      columnDefs={columnDefs}
                      ChangedData={getSelectedDataDetails}
                      close={handleClose}
                      fetchData={() => fetchData()}
                      //  loading={()=>loading}
                    />
                  </div>
                </Box>
              </Modal>
              <Modal
                open={openRemove}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box sx={{ ...style, width: 1200, minHeight: 500, padding: 2 }}>
                  <h3 id="child-modal-title">Delete Discounts</h3>
                  <div style={{ padding: 1 }}>
                    <DeleteModel
                      ChangedData={getSelectedDataDetails}
                      close={handleClose}
                      fetchData={() => fetchData()}
                    />
                  </div>
                </Box>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CustomBackdrop
        openBackDrop={backDropLoader}
        closeBackDrop={handleCloseBackdrop}
      />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default Discounts;

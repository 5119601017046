import React, { useEffect, useMemo, useState, useCallback } from "react";
import { uniq } from "lodash";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "./style.css";

export const CustomGrid = (props) => {
  const {
    rowData,
    columnData,
    getSelectedValues,
    tableHeight,
    paginationClicked,
  } = props;
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  useEffect(() => {
    var roeSelected = sessionStorage.getItem("checked")
      ? sessionStorage.getItem("checked")
      : [];

    if (gridApi && roeSelected.length > 0) {
      const rowSelectedData = roeSelected ? roeSelected.split(",") : [];

      rowSelectedData?.forEach((id) => {
        rowData?.forEach((data, index) => {
          if (data?.id === Number(id)) {
            const node = gridApi.getRowNode(index);
            if (node) {
              node.setSelected(true); // set to false to uncheck
            }
          }
        });
      });
    }
  }, [paginationClicked, rowData]);
//_____________________GET SELECTED CHECKBOX VALUES___________________________//
  const onSelectionChanged = () => {
    const selectedNodes = gridApi.getSelectedNodes();
   
    const selectedData = selectedNodes.map((node) => node.data);
    const uncheckedNodes = gridApi.rowModel.rowsToDisplay.filter(
      (node) => node.selected===false
    );
  
    const uncheckedData = uncheckedNodes.map((node) => node.data);
    getSelectedValues(selectedData,uncheckedData); // getSelectedValues fun() is sent the data for child to parent func()
     };

  const gridOptions = {
    onSelectionChanged,
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: false,
    };
  }, []);

  

  return (
    <div
      className="ag-theme-alpine"
      style={{ width: "100%", height: tableHeight }}
    >
      <AgGridReact
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnData}
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows
        gridOptions={gridOptions}
        overlayLoadingTemplate={
          '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
        }
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        suppressRowClickSelection
      />
    </div>
  );
};

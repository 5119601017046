import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody,Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {FiEdit2} from 'react-icons/fi'
import Search from '../../../Helper/CustomSearch'
import axios,{get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from '../../../Helper/Breadcrumbs'
import Loader from '../../../Helper/Loader';


const ViewPayment = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let breadcrumbData = props.location.state ? props.location.state.breadcrumbData : [
    {
      title : 'Payments',
      href : '/payment'
    },{
      title : 'View Payments',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  let mapStatus = {
    not_paid : 'Not Paid',
    advance_paid : 'Advance Paid',
    partially_paid : 'Partially Paid',
    fully_paid : 'Fully Paid',
    cancelled : 'Cancelled',
    paid : 'Paid',
    refunded : 'Refunded'
  };
let bookingId = props.location.state ? props.location.state.bookingId : null;
  const [tableData,settableData] = useState([]);
  const [loading, setloading] = useState(true);
  const [overlay, setOverlay] = useState(false);
  
  const cols = [
    {
      dataField : 'customer_name',
      text:"Customer Name",
      headerStyle : () => { return{width : '140px'}}
    },
    {
      dataField : 'mobile',
      text:"Customer mobile",
      headerStyle : () => { return{width : '140px'}}
    },
      {
        dataField : 'vendor_name',
        text:"Vendor Name",
        headerStyle : () => { return{width : '150px'}},
        formatter:(cell,row)=>{
          let val = row.vendor_name ? row.vendor_name : '';
          return(
          <div style={{maxWidth:"200px",lineHeight:"15px"}}>{val}</div>)
        }
      },
      {
        dataField : 'mandap_name',
        text:"Mandap Name",
        headerStyle : () => { return{width : '250px'}},
        formatter:(cell,row)=>{
          let val = row.mandap_name ? row.mandap_name : '';
          return(
          <div style={{maxWidth:"200px",lineHeight:"15px"}}>{val}</div>)
        }
      },
      {
        dataField : 'amount',
        text:"Amount",
        headerStyle : () => { return{width : '80px'}}
      },
      {
        dataField : 'payment_type',
        text:"Payment Type",
        headerStyle : () => { return{width : '90px'}},
      },
      {
        dataField : 'status',
        text:"Status",
        headerStyle : () => { return{width : '120px'}},
        formatter:cell=>{
          return <div>{mapStatus[cell]}</div>
        }
      },
      {
        dataField : 'created_on',
        text:"Date",
        headerStyle : () => { return{width : '140px'}},
        formatter:(cell,row)=>{
          let val = row.created_on ? row.created_on.replace('T',' ').replace('Z','').split('.')[0] : '';
          return(
          <div style={{maxWidth:"200px",lineHeight:"15px"}}>{val.slice(0,19)}</div>)
        }
      },
  ];

  const setLoader = (val) => {
    setloading(val);setOverlay(val);
  }


  const renderOnSearch = searchTerm => {
    setLoader(true);
    let url = config.service+'/payments/?&search='+searchTerm;
    if(bookingId) url +='&booking_id='+bookingId
    get(url,tokenKey).then(res=>{
      settableData(res.data);
      setLoader(false);
    }).catch(err=>{
        setLoader(false);
      if(err.response.status === 401) window.location.href = '/'
      toast.error('Search Failed', toastAttr);
    })
  }

  const rowEvents = {
    onClick : (e,row) =>{
      let bdData = [
        {
          title : 'Payments',
          href : '/payment'
        },{
          title : 'View Payments',
          href : '/viewPayment'
        },
        {
          title : 'Details',
          href : '#'
        }
      ]
      if(props.history.location.state){
        bdData = breadcrumbData.slice(0,2);
        Array.prototype.push.apply(bdData,[
          {
            title : 'View Payments',
            href : '/viewPayment',
            state : {
              breadcrumbData : breadcrumbData
            }
          },
          {
            title : 'Details',
            href : '#'
          }
        ])
      }
      
      history.push({
        pathname:"/viewPaymentForm",
        state : {
          id: row.id,
        editMode:true,
          breadcrumbData : bdData
        }
    })
    }
  }

  useEffect(() => {
    let url = config.service+'/payments/';
    if(bookingId) url +='?&booking_id='+bookingId
    get(url,tokenKey).then(res=>{
      settableData(res.data);
      setLoader(false);
    }).catch(err=>{ 
        setLoader(false);
      if(err.response.status === 401) window.location.href = '/'})
  }, []);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:tableData.length
  })

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
        <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
  </Col>
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row className='vendorList'>
        <Loader loading={loading} overlay={overlay}>
            <BootstrapTable  bootstrap4  wrapperClasses='table-responsive  pad-20' data={tableData} columns={cols} 
    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
    pagination={options} />
    </Loader>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default ViewPayment;
import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { DonutMultiple, DonutElement, DonutLabel  } from "react-donut-component";
import Loader from '../../../Helper/Loader'
// import {BarChart} from 'react-d3'
import { Bar, Pie } from 'react-chartjs-2'
import {FaUsers,FaFileAlt,FaImages,FaRegIdCard} from 'react-icons/fa'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,ArcElement
  } from 'chart.js';
  
const Vendor = (props) => {
    let history = useHistory();
    const [data, setData] = useState(props.data || {} );
    const [barData, setbarData] = useState({
      labels : [],
      datasets: [
        {
          label: 'Bookings',
          data: [],
          backgroundColor: '#F5347F',
        },
      ],
    });
    let newVendorCount = props.data.new_vendors.length;
    let newCustomerCount = props.data.new_customers.length;
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,ArcElement
      );
    const  routeToPage = (name) =>{
        let state = {};
        switch(name){
            case 'kycPending': 
                    state = {filterVal : 'kycPending' };
                    break;
            case 'photosPending' : 
                    state = {filterVal : 'photosPending' };
                    break;
            case 'draft' : 
                    state = {filterVal : 'draft' };
                    break;
        }
        history.push({
            pathname:"/vendor",
            state : state
        })
    }

    useEffect(() => {
        let chartData = [], labels = [],  temp = [1,2,3,4,5,6]
        props.data.vendor_mandap_list.map(obj=>{
            const random = Math.floor(Math.random() * temp.length);
            // if(obj.no_of_bookings)
            labels.push(obj.vendor_name)
            chartData.push(obj.no_of_bookings)
        })
        const data1 = {
            labels : labels,
            datasets: [
              {
                label: 'Bookings',
                data: chartData,
                backgroundColor: '#F5347F',
              },
            ],
          };
        setbarData(data1)
    }, []);
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
            text: 'Chart.js Bar Chart',
          },
        },
      };

      let pie_data = {
        labels: ['Vendors','Customers'],
        datasets: [
          {
            // label: '# of Votes',
            data: [newVendorCount,newCustomerCount],
            backgroundColor: [
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 206, 86, 0.8)',
            ],
            borderColor: [
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 206, 86, 0.8)',
            ],
            // borderWidth: 3,
            hoverOffset : 4,
          },
        ],
      };
      
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const d = new Date();
      let currentMonth = month[d.getMonth()] +' '+ d.getFullYear();
    let pendingTarget =data.monthly_target ? data.monthly_target.target_total - data.monthly_target.target_achieved : 0;
    return(
        <Row className="dbVendor dbBooking">
          <Loader loading={props.loading}>
            <Col md={12}>
            <Row>
            <Col md={12}>
                <Row className='pad-20'>
                    <Col md={3} className='statusDiv'>
                        <div className='contDiv bg-gradient-8' onClick={e=>routeToPage('all')}>
                            <span className='bookingTitle'>All Vendors</span>
                            <span className='circle bg8'>
                                <FaUsers color='aliceblue' />
                            </span>
                            <div className='bookingCount'>{data.total_vendor_count || 0}</div>
                        </div>
                    </Col>
                    <Col md={3} className='statusDiv'>
                        <div className='contDiv bg-gradient-9'  onClick={e=>routeToPage('kycPending')}>
                            <span className='bookingTitle'> KYC Pending</span>
                            <span className='circle  bg9'>
                                <FaRegIdCard color='white' />
                            </span>
                        <div className='bookingCount'>{data.kyc_pending_count || 0}</div>
                        </div>
                    </Col>
                    <Col md={3} className='statusDiv'>
                        <div className='contDiv bg-gradient-3' onClick={e=>routeToPage('photosPending')}>
                            <span className='bookingTitle'>Photos Pending</span>
                            <span className='circle bg3'>
                                <FaImages color='white' />
                            </span>
                            <div className='bookingCount'>{data.images_pending_count || 0}</div>
                        </div>
                    </Col>
                    <Col md={3} className='statusDiv'>
                        <div className='contDiv bg-gradient-4' onClick={e=>routeToPage('draft')}>
                            <span className='bookingTitle'>Draft</span>
                            <span className='circle bg4'>
                                <FaFileAlt color='white' />
                            </span>
                            <div className='bookingCount'>{data.draft_count || 0}</div>
                        </div>
                    </Col>
                </Row>
              </Col>
            </Row>
                {data.monthly_target ? 
                <Row>
                    <Col md={12}>
                        <div className='vendorTarget'>Vendor Target - {data.monthly_target.target_month}</div>
                    <DonutMultiple>
                        <DonutElement color='#EF4F57' name={"Achieved Target ("+data.monthly_target.target_achieved+")"}>{data.monthly_target.target_achieved}</DonutElement>
                        <DonutElement color='#E0DEDB' name={"Pending Target ("+pendingTarget+")"}>{pendingTarget}</DonutElement>
                        <DonutLabel>{(data.monthly_target.target_achieved/data.monthly_target.target_total)* 100}%</DonutLabel>
                    </DonutMultiple>
                    </Col>
                </Row> : ''}
                <Row className='pad-t-20'>
                    <Col md={12} style={{textAlign:'center'}}>
                        <h4>No. of Bookings made by Vendors - {currentMonth}</h4>
                        <Bar options={options} data={barData} />
                    </Col>
                </Row>
                {newCustomerCount > 0 || newVendorCount > 0 ?
                <Row className='pad-t-20'>
                    <Col md={12} style={{textAlign:'center'}}>
                        <h4>Recently Added - {currentMonth}</h4>
                        <div style={{height:'400px',width:'400px',textAlign:'center'}}><Pie options={options} data={pie_data} /></div>
                    </Col>
                </Row> : ''}
            </Col>
            </Loader>
        </Row>
    );
};

export default Vendor;
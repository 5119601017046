import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios, { get } from "axios";
import config from "../../../../config";
import moment from "moment";
import Button from "@mui/material/Button";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MuhurthaCalandar = () => {
  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "Muhurtham Calendar",
      href: "/masters/MuhurthaCalandar",
    },
  ];

  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };

  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedDates, setSelectedDates] = useState([]);
  const [years, setYears] = useState([]);
  const [statesData, setstatesData] = useState([]);
  const [selectedState, setcurrentState] = useState("Tamilnadu");
  const [selectedStateId, setSelectedStateId] = useState("41");
  const [isPageLoading, setLoader] = useState(false);
  const [isinitialPageLoading, setInitialPageLoading] = useState(false);
  const [isDataExist, setDataExist] = useState(false);
  const [primaryKey, setPrimarykeyId] = useState(null);
  const [autodata, setAutodata] = useState({ id: 41, name: "Tamilnadu" });

  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  useEffect(() => {
    setLoader(true);
    get(config.service + "/masters/state/", tokenKey)
      .then((res) => {
        setstatesData(res.data);
        setInitialPageLoading(true);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
    axios
      .get(
        config.service +
          `/booking/muhurtham_calendar?state=Tamilnadu&year=${currentYear}`
      )
      .then((response) => {
        setLoader(false);
        if (response.data.data.length > 0) {
          setSelectedDates(response.data.data[0].date);

          handleClose();
          setDataExist(true);

          setPrimarykeyId(response.data.data[0].id);
        } else if (response.data.data.length === 0) {
          setSelectedDates([]);
          setDataExist(false);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoader(false);
      });
  }, []);

  const handleClose = () => {
    setLoader(false);
  };
  const handleOpen = () => {
    setLoader(true);
  };

  const onUpdateClick = () => {
    setLoading(true);
    const resourceData = {
      state_id: selectedStateId,
      state: selectedState,
      year: selectedYear,
      date: selectedDates,
    };
    axios
      .put(
        `${config.service}/booking/muhurtham_calendar?id=${primaryKey}`,
        resourceData
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);

          setSelectedDates(response.data.data.date);
          toast("Sucessfully Updated");
        } else {
          setLoading(false);
          console.error("Failed to update resource:", response.statusText);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast("Failed to Update !!");
      });
  };

  const onSubmitClick = () => {
    const value = {
      state_id: selectedStateId,
      state: selectedState,
      year: selectedYear,
      date: selectedDates,
    };
    setLoading(true);
    if (selectedDates.length !== 0 && selectedState !== "") {
      fetch(config.service + "/booking/muhurtham_calendar", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      })
        .then((response) => {
          //   setSelectedDates(response.data.data.date)
          setLoading(false);
          toast("Sucessfully Added");
        })
        .catch((err) => {
          toast("Can't be Added");
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedYear(currentYear);
  }, [currentYear]);

  useEffect(() => {
    const generateYears = () => {
      const yearOptions = [];
      const minOffset = -1;
      const maxOffset = 30;
      for (let i = minOffset; i <= maxOffset; i++) {
        const yearFromSelect = currentYear + i;
        yearOptions.push(yearFromSelect);
      }
      return yearOptions;
    };

    setYears(generateYears());
  }, [selectedYear]);

  const onGet = () => {
    // eslint-disable-next-line no-lone-blocks
    {
      isinitialPageLoading && handleOpen();
    }
    if (selectedState !== null) {
      axios
        .get(
          config.service +
            `/booking/muhurtham_calendar?state=${selectedState}&year=${selectedYear}`
        )
        .then((response) => {
          if (response.data.data.length >= 1) {
            setSelectedDates(response.data.data[0].date);
            setLoader(true);
            handleClose();
            setDataExist(true);

            setPrimarykeyId(response.data.data[0].id);
          } else if (response.data.data.length === 0) {
            setSelectedDates([]);
            setDataExist(false);
            handleClose();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    setSelectedYear(year);
  };

  const handleDateClick = (month, day) => {
    const formattedDate = new Date(selectedYear, month - 1, day).toString();
    const initialDate = moment(formattedDate);
    const selectedDate = initialDate.format("YYYY-MM-DD");

    if (selectedDates.includes(selectedDate)) {
      setSelectedDates(selectedDates.filter((date) => date !== selectedDate));
    } else {
      setSelectedDates([...selectedDates, selectedDate]);
    }
  };

  const isDateSelected = (month, day) => {
    const formattedDate = new Date(selectedYear, month - 1, day).toString();
    const initialDate = moment(formattedDate);
    const selectedDate = initialDate.format("YYYY-MM-DD");

    return selectedDates.includes(selectedDate);
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  return (
    <>
      <Container className="vendor">
        <Card>
          <CardBody>
            <div className="card__title pageNav">
              <div className="bold-text">
                <Breadcrumbs data={breadcrumbData} />
              </div>
            </div>
            <Row className="formBtnsDiv mar-b-10">
              <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                <Box sx={{ maxWidth: "100%", margin: "0 0 10px 16px" }}>
                  <FormControl variant="standard" sx={{ m: 1, width: "80%" }}>
                    <Autocomplete
                      options={statesData}
                      getOptionLabel={(i) => i.name}
                      id="clear-on-escape"
                      disabled={statesData.length > 0 ? false : true}
                      value={autodata}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      onChange={(event, newValue) => {
                        setcurrentState(newValue.name);
                        setSelectedStateId(newValue.id);
                        setAutodata(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select State"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                <Box sx={{ width: "100%", margin: "0 0 10px 16px" }}>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: "80%" }}
                  >
                    <InputLabel
                      htmlFor="yearSelect"
                      id="demo-simple-select-label"
                    >
                      Select Year
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="yearSelect"
                      value={selectedYear}
                      label="Year"
                      onChange={handleYearChange}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={2}
                xl={2}
                xxl={2}
                className="d-flex flex-row justify-content-center align-items-start"
              >
                <Button
                  className="mt-3"
                  variant="contained"
                  onClick={() => {
                    onGet();
                  }}
                >
                  {" "}
                  Search
                </Button>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                {isDataExist === false ? (
                  <Button
                    className="mt-3"
                    variant="contained"
                    disabled={selectedState === null || selectedYear === ""}
                    onClick={() => {
                      onSubmitClick();
                    }}
                  >
                    {!loading ? (
                      "Add"
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 3,
                        }}
                      >
                        {
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        }
                        Add
                      </span>
                    )}
                  </Button>
                ) : (
                  <Button
                    className="mt-3"
                    variant="contained"
                    onClick={() => {
                      onUpdateClick();
                    }}
                  >
                    {!loading ? (
                      "Update"
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 3,
                        }}
                      >
                        {
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        }
                        Update
                      </span>
                    )}
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>

      <div>
        {selectedYear && (
          <div>
            <div className="calendar">
              <div className="wrap">
                <div className="currentYear">{selectedYear}</div>
                <div className="year Container">
                  <Row className="formBtnsDiv mar-b-10">
                    {months.map((month) => (
                      <Col xs={12} sm={12} md={6} lg={4} xl={3} xxl={3}>
                        <div key={month} className="month">
                          <h3
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              margin: "0 0 10px 0",
                              color: "#e83e8c",
                            }}
                            className="monthname"
                          >
                            {new Date(
                              selectedYear,
                              month - 1,
                              1
                            ).toLocaleString(undefined, { month: "long" })}
                          </h3>
                          <div className="content">
                            {[
                              ...Array(getDaysInMonth(selectedYear, month)),
                            ].map((_, day) => (
                              <span
                                key={day}
                                className={`day ${
                                  isDateSelected(month, day + 1) ? "booked" : ""
                                }`}
                                onClick={() => handleDateClick(month, day + 1)}
                              >
                                {day + 1}
                              </span>
                            ))}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isPageLoading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <ToastContainer />
      </div>
      <style>
        {`
      .calendar {
        margin: 0 auto 20px 10px;
        overflow-x:hidden;
      }
      .calendar .wrap {
        padding: 0 10px;
      }
      .currentYear {
        font-size: 32px;
        line-height: 1;
        font-weight: 400;
        font-style: normal;
        text-transform: none;
        text-align: center;
        color: #e83e8c;
        padding: 0;
        margin: 0 auto 50px;
      }
      .year {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        margin: 0 -10px;
      }
      .month {
        width: 100%;
        padding: 0 10px;
        margin: 0 0 20px;
        box-sizing: border-box;
      }
      .month .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .day {
        width: 14%;
        text-align: center;
        padding: 8px;
        box-sizing: border-box;
        font-weight: bold;
        font-size: 14px;
      }
      .booked {
        background-color: #e83e8c;
        color: #fff;
      }
      `}
      </style>
    </>
  );
};

export default MuhurthaCalandar;

import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toLower } from "lodash";
import axios, { post, get } from "axios";
import StatusSteps from "../../Helper/StatusSteps";
import Vendor from "./views/vendor";
import Mandapam from "./views/mandapam";
import Photos from "./views/photos";
import UploadVideos from "./views/uploadVideos";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import empty from "is-empty";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import ViewQRCode from "./views/ViewQRCode";
import ErrorHandling from "../../Helper/ErrorHandling";
import Options from "./views/options";
import BlockDates from "./views/blockDates";
import { FaChevronCircleDown } from "react-icons/fa";
import { Popover, Typography } from "@mui/material";

const VendorForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  let propsState = props.history.location.state;

  console.log('propsState',props)
  const [indexVal, setIndexVal] = useState(0);
  const [form, setForm] = useState({});
  const [payDetails, setPayDetails] = useState({});
  const [mandap, setMandap] = useState({});
  const [images, setImages] = useState([]);
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [showPayModal, setshowPayModal] = useState(false);
  const [payErrors, setPayErrors] = useState({});
  const [portal, setPortal] = useState(false);
  const [configVal, setconfigVal] = useState(null);
  const [showQRCode, setshowQRCode] = useState(false);
  const [handleErr, sethandleErr] = useState(false);
  const [errData, seterrData] = useState(null);
  const [showOptions, setshowOptions] = useState(false);
  const [currTab, setCurrTab] = useState("vendor");
  const [anchor, setAnchor] = useState(null);
  const [showCal, setshowCal] = useState(false);

  const allocationPopover = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };
  let mapIndex = {
    vendor: 0,
    mandapam: 1,
    photos: 2,
    videos: 3,
    offers:4
  };
  let breadcrumbData = [
    {
      title: propsState && propsState.tabValue ? "Mandap" : "Vendor",
      href: propsState && propsState.tabValue ? "/mandapList" : "/vendor",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];

  const script = document.createElement("script");
  script.src =
    "https://uat.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.esm.js";
  script.async = true;
  script.type = "module";

  const script1 = document.createElement("script");
  script1.src = "https://uat.billdesk.com/jssdk/v1/dist/billdesksdk.js";
  script1.async = true;
  script1.noModule = true;

  const link = document.createElement("link");
  link.href =
    "https://uat.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.css";
  link.rel = "stylesheet";

  let isAdminUser = sessionStorage.getItem("permission")
    ? JSON.parse(sessionStorage.getItem("permission"))[0].user_role ===
        "admin" || false
    : false;
  let isTelecaller = sessionStorage.getItem("permission")
  ? JSON.parse(sessionStorage.getItem("permission"))[0].user_role ===
      "telecaller" || false
  : false;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const [tab, setTab] = useState({
    vendor: false,
    mandapam: false,
    photos: false,
    videos: false,
    offers:false
  });
  const [tabData, setTabData] = useState([
    {
      name: "Details",
      id: "vendor",
      show: false,
    },
    {
      name: "Mandap Details",
      id: "mandapam",
      show: false,
    },
    {
      name: "Photos",
      id: "photos",
      show: false,
    },
    {
      name: "Videos",
      id: "videos",
      show: false,
    },{
      name: "Offers",
      id: "offers",
      show: false,
    },
  ]);
  const handleTab = (e, index) => {
    let tabValue = e.target.id;
    let val = {
      vendor: false,
      mandapam: false,
      photos: false,
      videos: false,
      offers:false
    };
    // if(tabValue === 'photos' || tabValue === 'videos') val = {...val, photos:true,videos : true}
    setIndexVal(index);
    setTab({
      ...val,
      [tabValue]: true,
    });
  };

  const setFormDetails = (data, keyName) => {
    setForm({
      ...form,
      ...data,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const setMandapDetails = (data, keyName) => {
    setMandap({
      ...mandap,
      ...data,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const setPhotoDetails = (data) => {
    setImages(data);
  };

  // const handleModalClose = () => {
  //   setshowPayModal(false);
  // };

  // const handlePayInput = (keyName, value) => {
  //   setPayDetails({
  //     ...payDetails,
  //     [keyName]: value,
  //   });
  //   if (keyName && !!payErrors[keyName])
  //     setPayErrors({
  //       ...payErrors,
  //       [keyName]: null,
  //     });
  // };

  const saveMap = (data) => {
    setMandap({
      ...mandap,
      latitude: data.lat,
      longitude: data.lng,
    });
  };

  const renderListView = () => {
    if (tab.vendor) history.push("/vendor");
    else renderMandapamList();
  };
  // const renderMandapam = () => {
  //   tabData.forEach(function (obj) {
  //     if (obj.id === "mandapam") obj.show = true;
  //     else obj.show = false;
  //   });
  //   setTabData(tabData);
  //   setTab({
  //     vendor: false,
  //     mandapam: true,
  //     photos: false,
  //     videos: false,
  //   });
  // };
  const renderMandapamList = () => {
    history.push({
      pathname: "/mandapList",
      state: {
        id: form.id,
      },
    });
  };
  const findVendorErrors = () => {
    const {
      vendor_name,
      owner_phone,
      // owner_name,
      // address_1,
      // category_id,
      // type_of_concern_id,
      // contact_email,
      // owner_aadhar,
      // owner_PAN,
      // enrollment_form,
      // cancelled_cheque,
    } = form;
    const newErrors = {};
    if (empty(vendor_name)) newErrors.vendor_name = "This is a required field";
    if (empty(owner_phone)) newErrors.owner_phone = "This is a required field";
    // if(propsState && propsState.approval){
    // if ( empty(address_1) ) newErrors.address_1 = 'This is a required field';
    // if ( empty(category_id) ) newErrors.category_id = 'This is a required field';
    // if ( empty(type_of_concern_id) ) newErrors.type_of_concern_id = 'This is a required field';
    // if ( empty(contact_email) ) newErrors.contact_email = 'This is a required field';
    // if ( empty(owner_name) ) newErrors.owner_name = 'This is a required field';
    // if ( empty(owner_aadhar) ) newErrors.owner_aadhar = 'This is a required field';
    // if ( empty(owner_PAN) ) newErrors.owner_PAN = 'This is a required field';
    // if ( empty(enrollment_form) ) newErrors.enrollment_form = 'This is a required field';
    // if ( empty(cancelled_cheque) ) newErrors.cancelled_cheque = 'This is a required field';
    // }
    return newErrors;
  };
  const findMandapErrors = () => {
    const {
      address_1,
      mandap_name,
      seating_capacity,
      dining_capacity,
      bike_parking_capacity,
      car_parking_capacity,
      food_type,
      ac_type,
      mandap_classification_id,
      mandap_type_id,
      vendor_id,
    } = mandap;
    const newErrors = {};
    if (empty(address_1)) newErrors.address_1 = "This is a required field";
    if (empty(mandap_name)) newErrors.mandap_name = "This is a required field";
    if (empty(seating_capacity))
      newErrors.seating_capacity = "This is a required field";
    if (empty(dining_capacity))
      newErrors.dining_capacity = "This is a required field";
    if (empty(car_parking_capacity))
      newErrors.car_parking_capacity = "This is a required field";
    if (empty(bike_parking_capacity))
      newErrors.bike_parking_capacity = "This is a required field";
    if (empty(ac_type)) newErrors.ac_type = "This is a required field";
    if (empty(food_type)) newErrors.food_type = "This is a required field";
    // if ( empty(no_of_rooms) ) newErrors.no_of_rooms = 'This is a required field';
    if (empty(mandap_classification_id))
      newErrors.mandap_classification_id = "This is a required field";
    if (empty(mandap_type_id))
      newErrors.mandap_type_id = "This is a required field";
    if (empty(vendor_id)) newErrors.vendor_id = "This is a required field";

    return newErrors;
  };
  const findPhotosErrors = () => {
    let newErrors = false;
    for (let i = 0; i < images.length; i++) {
      if (empty(images[i].image_name) || empty(images[i].image_category_id)) {
        newErrors = true;
        break;
      }
    }
    return newErrors;
  };




  const initiateErrorHandler = (errData, tabName) => {
    seterrData(errData);
    sethandleErr(true);
    setCurrTab(tabName);
  };

  const saveForm = (needScroll) => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "patch" : "post";
    if (tab.vendor) {
      const newErrors = findVendorErrors();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        toast.error("Enter all required data to proceed", toastAttr);
      } else {
        setLoader(true);
        let url = editMode
            ? config.service + "/masters/vendor/" + id + "/"
            : config.service + "/masters/vendor/create/",
          postData = new FormData(),
          data = form;

        if (typeof data.owner_aadhar === "string") delete data["owner_aadhar"];
        if (typeof data.owner_PAN === "string") delete data["owner_PAN"];
        if (typeof data.enrollment_form === "string")
          delete data["enrollment_form"];
        if (typeof data.cancelled_cheque === "string")
          delete data["cancelled_cheque"];
        if (typeof data.authorized_sign === "string")
          delete data["authorized_sign"];

        // data = JSON.parse(data)
        for (let key in data) {
          if (data.hasOwnProperty(key) && data[key] !== null)
            postData.append(key, data[key]);
        }

        axios({
          url: url,
          method: method,
          headers: tokenKey,
          data: postData,
        })
          .then((res) => {
            setForm(res.data);
            if (editMode) {
              toast.success("Saved successfully", toastAttr);
            } else {
              tabData.forEach(function (obj) {
                if (obj.id === "mandapam") obj.show = true;
                else obj.show = false;
              });
              setTabData(tabData);
              setTab({
                vendor: false,
                mandapam: true,
                photos: false,
                videos: false,
              });
            }
            setLoader(false);
            if (needScroll) window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response.status === 401) window.location.href = "/";
            else
              initiateErrorHandler(err.response && err.response.data, "vendor");
            toast.error("Saving Failed", toastAttr);
            setLoader(false);
          });
      }
    } else if (tab.mandapam) {
      const newErrors = findMandapErrors();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        toast.error("Enter all required data to proceed", toastAttr);
      } else {
        setLoader(true);
        let url = editMode
          ? config.service + "/masters/vendor_mandap/" + id + "/"
          : config.service + "/masters/vendor_mandap/create/";
        let data = {
          ...mandap,
          vendor_id: form.id || propsState.vendor_id,
        };
        if (mandap.vendor_id) data.vendor_id = mandap.vendor_id;
        if (mandap.latitude) {
          data.latitude = mandap.latitude.toString();
          data.longitude = mandap.longitude.toString();
        }

        if (empty(mandap.entry_time)) data.entry_time = null;
        if (empty(mandap.exit_time)) data.exit_time = null;
        if (empty(mandap.hall_height)) data.hall_height = null;
        if (empty(mandap.primary_stage_dim_ft))
          data.primary_stage_dim_ft = null;
        if (empty(mandap.secondary_stage_dim_ft))
          data.secondary_stage_dim_ft = null;
        delete data["vendor_image_ids"];
        axios({
          url: url,
          method: editMode ? "PUT" : method,
          headers: tokenKey,
          data: data,
        })
          .then((res) => {
            if (!editMode) setMandap(res.data);
            propsState = {
              ...propsState,
              mandap_id: editMode ? mandap.id : res.data.id,
            };
            // setImages(mandap.vendor_image_ids || []);
            tabData.forEach(function (obj) {
              if (obj.id === "photos") obj.show = true;
              else obj.show = false;
            });
            // setTabData(tabData);
            // setTab({
            //     vendor:false,
            //     mandapam:false,
            //     photos:true,
            //         })
            setLoader(false);
            toast.success("Saved successfully", toastAttr);
            if (!editMode) setshowOptions(true);
            if (needScroll) window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response.status === 401) window.location.href = "/";
            else
              initiateErrorHandler(
                err.response && err.response.data,
                "mandapam"
              );
            toast.error("Saving Failed", toastAttr);
            setLoader(false);
          });
      }
    } else if (tab.photos) {
      const newErrors = findPhotosErrors();
      if (newErrors) {
        toast.error(
          "Image name and category are required for all uploaded images",
          toastAttr
        );
      } else {
        setLoader(true);
        let url = editMode
            ? config.service + "/masters/vendor_mandap/" + id + "/"
            : config.service + "/masters/vendor_images/create/",
          photos = {
            vendor_mandap_id: mandap.id || propsState.mandap_id,
            mandap_images: [],
          };
        images.map((obj) => {
          obj.vendor_mandap_id = mandap.id || propsState.mandap_id;
          photos.mandap_images.push(obj);
        });

        let postData = { mandap_images: photos.mandap_images };
        if (editMode)
          postData = {
            ...mandap,
            vendor_image_ids: photos.mandap_images,
          };
        if (!editMode && empty(photos.mandap_images)) {
          setLoader(false);
          toast.success("Saved successfully", toastAttr);
          setTimeout(() => renderListView(), 1000);
          return false;
        }
        axios({
          url: url,
          method: editMode ? "PUT" : method,
          headers: {
            Authorization: "Token " + sessionStorage.getItem("tokenKey"),
            // "Content-type": "multipart/form-data",
          },
          data: postData,
        })
          .then((res) => {
            setLoader(false);
            toast.success("Saved successfully", toastAttr);
            setTimeout(() => renderListView(), 1000);
          })
          .catch((err) => {
            setLoader(false);
            if (err.response.status === 401) window.location.href = "/";
            else
              initiateErrorHandler(err.response && err.response.data, "photos");
            toast.error("Saving Failed", toastAttr);
          });
      }
    }
  };

  const sendForApprove = (needScroll) => {
    let id = editMode ? propsState.id : null,
      method = "post";
    if (tab.vendor) {
      const newErrors = findVendorErrors();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        toast.error("Enter all required data to proceed", toastAttr);
      } else {
        setLoader(true);
        // let url = editMode
        //     ? config.service + "/masters/vendor/" + id + "/"
        //     : config.service + "/masters/vendor/create/",
        let url = config.service + "/masters/vendor_mandap_pending/create/",
          postData = new FormData(),
          data = form;

        if (typeof data.owner_aadhar === "string") delete data["owner_aadhar"];
        if (typeof data.owner_PAN === "string") delete data["owner_PAN"];
        if (typeof data.enrollment_form === "string")
          delete data["enrollment_form"];
        if (typeof data.cancelled_cheque === "string")
          delete data["cancelled_cheque"];
        if (typeof data.authorized_sign === "string")
          delete data["authorized_sign"];

        data['is_approved'] = false;
        data['is_rejected'] = false;
        data['is_edited'] = editMode ? true : false;
        data['vendor_mandap_table_id'] = id;

        // data = JSON.parse(data)
        for (let key in data) {
          if (data.hasOwnProperty(key) && data[key] !== null)
            postData.append(key, data[key]);
        }

        console.log('postData123',postData);
        axios({
          url: url,
          method: method,
          headers: tokenKey,
          data: postData,
        })
          .then((res) => {
            setForm(res.data);
            if (editMode) {
              toast.success("Saved successfully", toastAttr);
            } else {
              tabData.forEach(function (obj) {
                if (obj.id === "mandapam") obj.show = true;
                else obj.show = false;
              });
              setTabData(tabData);
              setTab({
                vendor: false,
                mandapam: true,
                photos: false,
                videos: false,
              });
            }
            setLoader(false);
            if (needScroll) window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response.status === 401) window.location.href = "/";
            else
              initiateErrorHandler(err.response && err.response.data, "vendor");
            toast.error("Saving Failed", toastAttr);
            setLoader(false);
          });
      }
    } else if (tab.mandapam) {
      const newErrors = findMandapErrors();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        toast.error("Enter all required data to proceed", toastAttr);
      } else {
        setLoader(true);
        // let url = editMode
        //   ? config.service + "/masters/vendor_mandap/" + id + "/"
        //   : config.service + "/masters/vendor_mandap/create/";
        let url = config.service + "/masters/vendor_mandap_pending/create/";
        let data = {
          ...mandap,
          vendor_id: form.id || propsState.vendor_id,
        };
        if (mandap.vendor_id) data.vendor_id = mandap.vendor_id;
        if (mandap.latitude) {
          data.latitude = mandap.latitude.toString();
          data.longitude = mandap.longitude.toString();
        }

        if (empty(mandap.entry_time)) data.entry_time = null;
        if (empty(mandap.exit_time)) data.exit_time = null;
        if (empty(mandap.hall_height)) data.hall_height = null;
        if (empty(mandap.primary_stage_dim_ft))
          data.primary_stage_dim_ft = null;
        if (empty(mandap.secondary_stage_dim_ft))
          data.secondary_stage_dim_ft = null;
        delete data["vendor_image_ids"];

        data['is_approved'] = false;
        data['is_rejected'] = false;
        data['is_edited'] = editMode ? true : false;
        data['vendor_mandap_table_id'] = id;


        console.log('postData123',data);


        axios({
          url: url,
          method: method,
          headers: tokenKey,
          data: data,
        })
          .then((res) => {
            if (!editMode) setMandap(res.data);
            propsState = {
              ...propsState,
              mandap_id: editMode ? mandap.id : res.data.id,
            };
            // setImages(mandap.vendor_image_ids || []);
            tabData.forEach(function (obj) {
              if (obj.id === "photos") obj.show = true;
              else obj.show = false;
            });
            // setTabData(tabData);
            // setTab({
            //     vendor:false,
            //     mandapam:false,
            //     photos:true,
            //         })
            setLoader(false);
            toast.success("Saved successfully", toastAttr);
            if (!editMode) setshowOptions(true);
            if (needScroll) window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response.status === 401) window.location.href = "/";
            else
              initiateErrorHandler(
                err.response && err.response.data,
                "mandapam"
              );
            toast.error("Saving Failed", toastAttr);
            setLoader(false);
          });
      }
    } else if (tab.photos) {
      const newErrors = findPhotosErrors();
      if (newErrors) {
        toast.error(
          "Image name and category are required for all uploaded images",
          toastAttr
        );
      } else {
        setLoader(true);
        let url = editMode
            ? config.service + "/masters/vendor_mandap/" + id + "/"
            : config.service + "/masters/vendor_images/create/",
          photos = {
            vendor_mandap_id: mandap.id || propsState.mandap_id,
            mandap_images: [],
          };
        images.map((obj) => {
          obj.vendor_mandap_id = mandap.id || propsState.mandap_id;
          photos.mandap_images.push(obj);
        });

        let postData = { mandap_images: photos.mandap_images };
        if (editMode)
          postData = {
            ...mandap,
            vendor_image_ids: photos.mandap_images,
          };
        if (!editMode && empty(photos.mandap_images)) {
          setLoader(false);
          toast.success("Saved successfully", toastAttr);
          setTimeout(() => renderListView(), 1000);
          return false;
        }
        axios({
          url: url,
          method: editMode ? "PUT" : method,
          headers: {
            Authorization: "Token " + sessionStorage.getItem("tokenKey"),
            // "Content-type": "multipart/form-data",
          },
          data: postData,
        })
          .then((res) => {
            setLoader(false);
            toast.success("Saved successfully", toastAttr);
            setTimeout(() => renderListView(), 1000);
          })
          .catch((err) => {
            setLoader(false);
            if (err.response.status === 401) window.location.href = "/";
            else
              initiateErrorHandler(err.response && err.response.data, "photos");
            toast.error("Saving Failed", toastAttr);
          });
      }
    }
  };

  console.log('mandap.offers_list',mandap.offers_list)
  // useEffect(()=>{
  //   if(mandap.offers_list !==null && mandap.offers_list !=="" && mandap.offers_list !==undefined){
  //     const Val=JSON.parse(mandap.offers_list)
  //     console.log('Val',Val)
  //   }
  // },[mandap])

  const getApprovalBtnValue = () => {
    let status = tab.vendor
        ? form.current_status
        : tab.mandapam
        ? mandap.status
        : null,
      val = "",
      mandap_statusMap = {
        draft: "Submit",
        waiting_for_approval: "Approve",
        approved: "Publish",
        rejected: "Submit",
        publish: "UnPublish",
        unpublish: "Publish",
      },
      vendor_statusMap = {
        draft: "Submit",
        waiting_for_approval: "Approve",
        approved: "Register",
        rejected: "Submit",
        // registered : 'Publish',
        publish: "UnRegister",
        registered: "UnRegister",
        unregistered: "Register",
      };
    if (tab.vendor) {
      if (!isAdminUser && vendor_statusMap[status] !== "Submit") return false;
      else return vendor_statusMap[status];
    } else if (tab.mandapam) {
      if (!isAdminUser && mandap_statusMap[status] !== "Submit") return false;
      else return mandap_statusMap[status];
    }
  };

  const triggerApproval = (btnName) => {
    // setapprovalBtn(true);
    propsState.approval = true;
    const newErrors = tab.vendor ? findVendorErrors() : findMandapErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required data to proceed", toastAttr);
    } else {
      let data = tab.vendor
        ? {
            btn_name: toLower(btnName),
            vendor_id: form.id,
            mandap_id: null,
          }
        : {
            btn_name: toLower(btnName),
            vendor_id: null,
            mandap_id: mandap.id,
          };
      setLoader(true);
      post(config.service + "/masters/dynamic_button_action/", data, {
        headers: tokenKey,
      })
        .then((res) => {
          toast.success("Action perfomed successfully", toastAttr);
          if (tab.vendor)
            get(config.service + "/masters/vendor/" + form.id + "/", {
              headers: tokenKey,
            }).then((res) => {
              setForm(res.data);
              setLoader(false);
            });
          else if (tab.mandapam)
            get(config.service + "/masters/vendor_mandap/" + mandap.id + "/", {
              headers: tokenKey,
            }).then((res) => {
              setMandap(res.data);
              setLoader(false);
            });
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          toast.error("Action failed", toastAttr);
          setLoader(false);
        });
    }
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const showPayDetailsModal = () => {
    setPayDetails({
      mobile: form.mobile,
      email: form.email,
    });
    setshowPayModal(true);
  };

  const proceedToImages = () => {
    setTab({
      vendor: false,
      mandapam: false,
      photos: true,
      videos: false,
      offers:false
    });
    tabData.forEach(function (obj) {
      if (obj.id === "photos" || obj.id === "videos" || obj.id==="offers") obj.show = true;
      else obj.show = false;
    });

    setTabData(tabData);
    setshowOptions(false);
  };

  const proceedToPricing = () => {
    history.push({
      pathname: "/pricing",
      state: {
        data: mandap,
      },
    });
  };

  const proceedToSeoTags = () => {
    history.push({
      pathname: "/seotags",
      state: {
        data: mandap,
      },
    });
  };

  const closeErrorHandling = () => {
    sethandleErr(false);
    seterrData(null);
  };

  useEffect(() => {
    if (portal) {
      const timer = setTimeout(() => {
        document.getElementById("result").innerHTML = "";
        var xmlhttp = new XMLHttpRequest();
        var jsonObj = "";
        window.loadBillDeskSdk(configVal);
        setLoader(false);
        setPortal(false);
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [portal]);

  useEffect(() => {
    let keyVal = (propsState && propsState.tabValue) || "vendor";
    setTab({
      vendor: false,
      mandapam: false,
      photos: false,
      videos: false,
      [keyVal]: true,
    });
    tabData.forEach(function (obj) {
      if (obj.id === keyVal) obj.show = true;
      else obj.show = false;
    });
    setTabData(tabData);
    document.body.appendChild(script);
    document.body.appendChild(script1);
    document.body.appendChild(link);
    // setLoader(false)
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script1);
      document.body.removeChild(link);
    };
  }, []);

  let BtnLabel = "Next";
  if (editMode) BtnLabel = "Save";
  else if (!editMode && tab.photos) BtnLabel = "Save";
  let approvalBtn = getApprovalBtnValue();
  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                {/* change heading */}
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              {(propsState && !propsState.isProfile) || !propsState ? (
                <Row className="formBtnsDiv">
                  <Col md={6} xs={7}>
                    {isTelecaller ?
                     <Button
                     variant="primary"
                     onClick={(e) => {
                      sendForApprove();
                     }}
                   >
                     Send for approve
                   </Button>
                    :!tab.videos ? (
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          saveForm();
                        }}
                      >
                        {BtnLabel}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      variant="secondary"
                      onClick={(e) => {
                        renderListView();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  {tab.mandapam && editMode && !loading ? (
                    <Col md={2} xs={5}>
                      <div
                        className="userDetails"
                        style={{ width: "max-content", cursor: "pointer" }}
                        aria-describedby="simple-popover"
                        variant="contained"
                        onClick={allocationPopover}
                      >
                        <div className="formActions">
                          Actions{" "}
                          <span>
                            <FaChevronCircleDown color="#ec2d7c" />
                          </span>
                        </div>
                      </div>
                      <Popover
                        open={Boolean(anchor)}
                        anchorEl={anchor}
                        id="simple-popover"
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Typography variant="">
                          <div className="userPopover">
                            <div onClick={() => setshowCal(true)}>
                              Allocate Mandap
                            </div>
                          </div>
                        </Typography>
                      </Popover>
                    </Col>
                  ) : (
                    ""
                  )}
                  {showCal ? (
                    <BlockDates
                      handleClose={() => {
                        setshowCal(false);
                        handleClose();
                      }}
                      mandapId={mandap.id}
                    />
                  ) : (
                    ""
                  )}

                  {/* <Col md={2} className="viewMandap">
            {editMode && tab.vendor ?<div style={{paddingTop:'6px',display:'inline-block', cursor:'pointer'}}> <a className='' onClick={e=>{renderMandapamList()}}>View Mandap</a></div> : ''}
            </Col> */}
                </Row>
              ) : (
                ""
              )}
              <Row className="formBtnsDiv">
                <Col md={12} style={{ margin: "5px 0" }}>
                  {editMode && approvalBtn ? (
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        triggerApproval(approvalBtn);
                      }}
                    >
                      {approvalBtn}
                    </Button>
                  ) : (
                    ""
                  )}
                  {approvalBtn === "Approve" ? (
                    <Button
                      variant="primary"
                      className="btnReject"
                      onClick={(e) => {
                        triggerApproval("reject");
                      }}
                    >
                      Reject
                    </Button>
                  ) : (
                    ""
                  )}
                  {/* {editMode && approvalBtn !== 'publish' ? <Button variant='primary' onClick={e=>{triggerPaymentPortal()}}>Re.1 Verification</Button>  : ''} */}
                  {editMode ? (
                    <StatusSteps
                      data={{
                        status: tab.vendor
                          ? form.current_status
                          : mandap.status,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className="formDiv">
                <Col md={8}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tabObj, index) => {
                          let disCls = tabObj.disable;
                          let keys = Object.keys(tab).filter((k) => tab[k]);
                          return tabObj.show ? (
                            <>
                              <a
                                className={`nav-item nav-link ${
                                  mapIndex[keys[0]] === index ? "active" : ""
                                }`}
                                id={tabObj.id}
                                name={index}
                                data-toggle="tab"
                                role="tab"
                                aria-controls="nav-details"
                                aria-selected="true"
                                onClick={(e) => handleTab(e, index)}
                              >
                                {tabObj.name}
                              </a>
                            </>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                </Col>
                {/* <Col md={2} className='qrCode'>
                {tab.vendor && editMode? <ImQrcode fontSize={'2.5rem'} title='View QRCode' onClick={e=>setshowQRCode(true) } /> : ''}
            </Col> */}
                {editMode && tab.vendor && form.id ? (
                  <Col md={4} className="navigations">
                    <div className="softButton" onClick={renderMandapamList}>
                      <span>VIEW MANDAP</span>
                    </div>
                    <div
                      className="softButton"
                      onClick={(e) => setshowQRCode(true)}
                    >
                      <span>SCAN QRCODE</span>
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                {editMode && tab.mandapam && mandap.id && !isTelecaller ? (
                  <>
                    <Col md={4} className="navigations">
                      <div className="softButton" onClick={proceedToSeoTags}>
                        <span>UPDATE SEOTAGS</span>
                      </div>
                      <div className="softButton" onClick={proceedToPricing}>
                        <span>UPDATE PRICING</span>
                      </div>
                      <br />
                      <div className="softButton" onClick={proceedToImages}>
                        <span>UPLOAD IMAGES</span>
                      </div>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md={12}>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      {/* <Loader loading={loading} overlay={overlay}> */}
                      {tab.vendor ? (
                        <Vendor
                          form={form}
                          propsData={propsState}
                          loading={loading}
                          overlay={overlay}
                          save={setFormDetails}
                          errors={errors}
                          setLoader={setLoader}
                        />
                      ) : (
                        ""
                      )}
                      {tab.mandapam ? (
                        <Mandapam
                          mandap={mandap}
                          propsData={propsState}
                          loading={loading}
                          overlay={overlay}
                          save={setMandapDetails}
                          errors={errors}
                          setLoader={setLoader}
                          saveMap={saveMap}
                        />
                      ) : (
                        ""
                      )}
                      {tab.photos ? (
                        <Photos
                          mandap={mandap}
                          images={images}
                          propsData={propsState}
                          loading={loading}
                          overlay={overlay}
                          save={setPhotoDetails}
                          mandapId={mandap.id}
                          errors={errors}
                          setLoader={setLoader}
                        />
                      ) : (
                        ""
                      )}
                      {tab.videos ? (
                        <UploadVideos
                          loading={loading}
                          overlay={overlay}
                          mandapId={mandap.id}
                          setLoader={setLoader}
                        />
                      ) : (
                        ""
                      )}
                      {/* </Loader> */}
                    </Form>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} style={{ textAlign: "center" }}>
                  {isTelecaller ?
                     <Button
                     variant="primary"
                     onClick={(e) => {
                      sendForApprove();
                     }}
                   >
                     Send for approve
                   </Button>
                    :!tab.videos ? (
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        saveForm(true);
                      }}
                    >
                      {BtnLabel}
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              {showQRCode ? (
                <ViewQRCode
                  handleClose={(e) => setshowQRCode(false)}
                  data={form}
                />
              ) : (
                ""
              )}
              {handleErr ? (
                <ErrorHandling
                  title={currTab}
                  closeErrorHandling={closeErrorHandling}
                  error={errData}
                />
              ) : (
                ""
              )}
              {showOptions ? (
                <Options
                  data={mandap}
                  handleClose={(e) => setshowOptions(false)}
                  proceedToImages={proceedToImages}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default VendorForm;

import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { uniqBy } from "lodash";
import { Col, Row, Button, Spinner } from "reactstrap";
import { CustomClientSideGrid } from "../../../../shared/ClientSidePaginationGrid";
import PropTypes from "prop-types";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModel from "../../../../shared/ConfirmationModel";
import config from "../../../../config";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DeleteModel({ ChangedData, close, fetchData }) {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openNested, setOpenNested] = useState(false);
  const [confirm, setConfirm] = useState(false);
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  const data = uniqBy(ChangedData)?.filter((i) => i?.has_discount === true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const columnDefs = [
    {
      headerName: "ID",
      field: "id",

      filter: true,
      width: "100px",

      resizable: true,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "Venue Name",
      field: "mandap_name",
      filter: true,
      resizable: true,
      width: 300,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "City",
      field: "city_name",
      filter: true,
      resizable: true,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "Venue Type",
      field: "mandap_type_name",
      filter: true,
      width: 180,
      resizable: true,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "Discount Status",
      field: "has_discount",
      resizable: true,
      filter: true,
      width: 180,
      cellRenderer: (params) => {
        return params?.value === true ? "Available" : "UnAvailable";
      },
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
        defaultOption: "equals",
      },
    },

    {
      headerName: "Discount(%)",
      field: "discount_percent",
      resizable: true,
      filter: true,
      width: 150,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "Address",
      field: "address_1",

      resizable: true,
      filter: true,
      width: 400,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
  ];
  useEffect(() => {
    if (confirm === true) {
      const FinalData = data.map((i) => {
        return i?.id;
      });
      setLoading(true);
      axios
        .put(
          config.service + "/masters/mandap_discount_bulk_remove/",
          FinalData,
          {
            headers: tokenKey,
          }
        )
        .then(function (response) {
          fetchData();
          toast.success("Mandapam  Discount Removed Sucessfully ");
          setLoading(false);

          close();
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Cant Be Removed");
        });
    }
  }, [confirm]);

  const handlePost = () => {
    setOpenNested(true);
  };

  const handleCloseModal = () => {
    setOpenNested(false);
  };
  const handleConfirmation = () => {
    setConfirm(true);
    handleCloseModal();
  };

  return (
    <>
      <Row>
        <Col xs={10}></Col>
        <Col xs={2}>
          <Button
            color="primary"
            onClick={() => handlePost()}
            disabled={data?.length === 0}
          >
            Delete
          </Button>
          <Button onClick={() => close()}>Cancel</Button>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col>Total Number of Records to be Changed: {data?.length}</Col>
      </Row>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Delete Discount List" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100px",
              }}
            >
              <Spinner color="primary"></Spinner>{" "}
            </div>
          ) : (
            <CustomClientSideGrid
              rowData={data}
              columnDefs={columnDefs}
              tableHeight={"400px"}
            />
          )}
        </CustomTabPanel>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ConfirmationModel
        operation={"Remove"}
        val={openNested}
        handleConfirmation={handleConfirmation}
        modelName={"Discount"}
        onclose={handleCloseModal}
      />
    </>
  );
}
DeleteModel.propTypes = {
  ChangedData: PropTypes.any.isRequired,
  close: PropTypes.any.isRequired,
};

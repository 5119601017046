import React,{useState,useEffect,} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config';
import Loader from '../../../Helper/Loader'
import Breadcrumbs from '../../../Helper/Breadcrumbs';


const FeedBackForm = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(propsState && propsState.editMode || false);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [vendorSeeds,setVendorSeeds]=useState([]);
  const [mandapName,setMandapName]=useState([]);
  const [selected, setSelected] = useState();
  

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
    let breadcrumbData = [
      {
        title:'Masters',
        href:'/masters'
      },
      {
        title:'FeedBack',
        href:'/masters/feedback'
      },
      {
        title:editMode ? 'View' : 'Create',
        href:'#'
      }
    ]
  const [tab, setTab] = useState({
      details:true,
  });
    const [tabData, setTabData] = useState([
        {
            name:'FeedBack Details',
            id:'details',
            show:true
        }
    ]);
    

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }
   
 
  const renderListView = () => {
      history.push("/masters/feedback")
  }

  const findErrors = () => {
    const { name } = form
    const newErrors = {}


    return newErrors;
   }

  const saveForm = () => {
    let id =editMode ? propsState.id : null, method = editMode ? 'put' : 'post';
    const newErrors = findErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors)
      toast.error('Enter all required details to proceed', toastAttr);
      
    } else {
      setLoader(true);
        let url = editMode ? config.service+'/masters/feedback/update/' : config.service+'/masters/feedback/create/', data=JSON.parse(JSON.stringify(form));
        data.feedback_id = form.id
        axios({
            url: url,
            method: method,
            headers: tokenKey,
            data: data,
            
          }).then(res=>{
              if(editMode) setForm(res.data)
              toast.success('Saved successfully', toastAttr);
              setLoader(false);
        }).catch(err=>{
          if(err.response.status === 401) window.location.href = '/'
          else
            toast.error('Saving Failed', toastAttr);
            setLoader(false);
        })
    }
  }
  
  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }
  useEffect(() => {
   
    if(propsState && propsState.editMode)
        get(config.service+'/masters/feedback/'+propsState.id,{headers:tokenKey}).then(res=>{
          res.data.vendor_id = res.data.vendor_id.id
          res.data.mandap_id =res.data.mandap_id.id
            setForm(res.data);
            
            
        }).catch(err=>{
          setLoader(false);

        })
get(config.service+'/masters/base/vendor/',{headers:tokenKey}).then(resp=>{
  setVendorSeeds([...resp?.data])
      let list = [];
      resp.data.map(obj=>{
        list.push({value:obj.id,label:obj.vendor_name})
      })
      setLoader(false);
    }).catch(err=>{
      setLoader(false);
    })
    get(config.service+'/masters/base/vendor-mandap/',{headers:tokenKey}).then(res=>{
      setMandapName([...res?.data])
      let mandap =[];
      res.data.map(obj=>{
          mandap.push({value:obj.id,label:obj.mandap_name});
      })
      setLoader(false);
    }).catch(err=>{
      setLoader(false);
    })
    }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
 
 const onChangeHandler = (e) => {
  const index = e.target.selectedIndex;
  const el = e.target.childNodes[index]
  const option =  el.getAttribute('id');
  setForm({...form,vendor_id:option})
  setLoader(true) 
  get(config.service+'/masters/base/vendor-mandap/?&vendor_id='+option,{headers:tokenKey}).then(res=>{
    setLoader(false);
  }).catch(err=>{
    setLoader(false);
  })
}
const onChangeHandler1 = (e) => {
  const index = e.target.selectedIndex;
  const el = e.target.childNodes[index]
  const option =  el.getAttribute('id');
  setForm({...form,mandap_id:option})
}

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
          <div className="bold-text">
            <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv'>
            <Col md={12}>
                    <Button variant='primary' onClick={e=>{saveForm()}}>Save</Button> 
                    <Button variant='secondary' onClick={e=>{renderListView()}}>Cancel</Button>
            </Col>
        </Row>
        <Row className='formBtnsDiv'>
            <Col md={12} style={{margin:'5px 0'}}>

            </Col>
        </Row>
        <Row className='formDiv'>
            <Col md={12}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                            {tabData.map((tab,index)=>{
                                let disCls = tab.disable;
                                return tab.show ? (
                                    <a className={`nav-item nav-link active`} 
                                    id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'>
                                        {tab.name}
                                    </a>
                                ) : ''; 
                            })}
                        </div>
                    </div>
                </nav>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                    <Loader loading={loading} overlay={overlay}>
                        {tab.details ? 
                        <Row><Col md={12}>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label>Rating</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                    <Form.Control type='text' name="rating" value={form.rating} onChange={e=>handleInput('rating',e.target.value)} isInvalid={!! errors.rating}/>
                                      <Form.Control.Feedback type='invalid'>
                                        { errors.name }
                                      </Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label>Comments</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                    <Form.Control type='text' name="comments" value={form.comments} onChange={e=> handleInput('comments',e.target.value)} isInvalid={!! errors.comments} />
                                      <Form.Control.Feedback type='invalid'>
                                          { errors.comments }
                                      </Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <Row>
                                  <Col md={4}>
                                  <Form.Label>Vendor Names</Form.Label>
                                  </Col>
                                  <Col md={7}>
                                  <Form.Control required as="select" name="vendor_name" value={form.vendor_id} onChange={e=>onChangeHandler(e)}>
                                          {vendorSeeds?.map(item => (<option id={item?.id} value={item?.id}>{item?.vendor_name}</option>))}
                                        </Form.Control>
                                    
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4}>
                                    <Form.Label>Mandap Names</Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control requried as="select" name="mandap_name" value={form.mandap_id} onChange={e=>onChangeHandler1(e)}>
                                      {mandapName?.map(item =>(<option id={item?.id} value={item?.id}>{item.mandap_name}</option>))}
                                    </Form.Control>
                                  </Col>
                                </Row>
                            </Col>
                        </Row>
            </Col></Row>
                        : ''}
                    </Loader>
                    </Form>
                </div>
            </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);

};

export default FeedBackForm;
import React from "react";
import LogInForm from "./components/LogInForm.js";

const icon = `${process.env.PUBLIC_URL}/img/logo.png`;

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <img className="" src={icon} alt="Logo" />
          {/* <h2 className="account__title">Book Wed Go</h2> */}
          {/* <h4 className="account__subhead subhead">Start your business easily</h4> */}
        </div>
        <LogInForm onSubmit={() => {}} />
      </div>
    </div>
  </div>
);

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles

import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody,Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {FaUserTie} from 'react-icons/fa'
import {HiPencilAlt, HiOutlineTrash} from 'react-icons/hi'
import Search from '../../../Helper/CustomSearch'
import axios,{get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {FiEdit2} from 'react-icons/fi'
import Breadcrumbs from '../../../Helper/Breadcrumbs';
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'

const icon = `${process.env.PUBLIC_URL}/img.png`; 

const Reviews = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let propsState = props.history.location.state;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
    let breadcrumbData = [
        {
            title : 'Manage',
            href : '/settings'
          },
          {
            title : 'Reviews',
            href : '#'
          }
    ]
  const [tableData,settableData] = useState([]);
  const [showModal, setShowModal] = useState(false) 
  const [rowData, setRowData] = useState(null) 

  const handleClose = () => {
    setShowModal(false);

}
  const deleteRow = row => {
    axios.delete(config.service+'/customer/mandap-review/'+row.id,tokenKey).then(resp=>{
      get(config.service+'/customer/mandap-review/',tokenKey).then(res=>{
      settableData(res.data);
    })
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/'
      // toast.error('Deletion Failed', toastAttr);
    })
  }

  const renderForm = (row) => {
      if(row) history.push({
          pathname:"/settings/reviewsForm",
          state : {
            id: row.id,
            editMode:true,
          }
      })
    else history.push({
        pathname:"/settings/reviewsForm",
    })
  }

  const renderOnSearch = searchTerm => {
    get(config.service+'/customer/mandap-review/?&search='+searchTerm,tokenKey).then(res=>{
      settableData(res.data);
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/';
      toast.error('Search Failed', toastAttr);
    })
  }

  useEffect(() => {
    get(config.service+'/customer/mandap-review/',tokenKey).then(res=>{
      settableData(res.data);
    }).catch(err=>{ if(err.response.status === 401) window.location.href = '/'})
  }, []);
  const cols = [
    {
      dataField : 'mandap_name',
      text:'Mandapam Name',
      headerStyle : () => { return{width : '400px'}}
    },
    {
        dataField : 'comments',
        text:'Comments',
        headerStyle : () => { return{width : '300px'}},
        formatter:(cell,row)=>{
            return(
                <div style={{cursor:'pointer',textDecoration:'underline'}} onClick={()=>{setShowModal(true);setRowData(row)}}>Click here</div>
            )
        }    
      },
    {
      dataField : 'id',
      text:'Actions',
      headerStyle : () => { return{width : '80px'}},
      events : {
        onClick : e=>{return e.stopPropagation(); }
      },
      formatter:(cell,row)=>{
        return(
        <div className='tableActions'>
          <React.Fragment>
            {/* <Link to={{pathname:"/settings/reviewsForm",state:{id:row.id}}}>
              <FiEdit2 title='Edit' />
            </Link> */}
            <HiOutlineTrash size={16} title='Delete'  onClick={e => deleteRow(row) } />
          </React.Fragment>
        </div>)
      }
    }
  ]
  const rowEvents = {
    onClick : (e,row) =>{
      history.push({
        pathname:"/settings/reviewsForm",
        state : {
          id: row.id,
        editMode:true,
        }
    })
    }
  }
  
  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:tableData.length
  })

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
          <div className="bold-text">
            <Breadcrumbs data={breadcrumbData}/>
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
    <Button variant='primary' onClick={e=>renderForm()}>Create</Button>
  </Col>
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row className='vendorList' >
        <BootstrapTable  bootstrap4  wrapperClasses='table-responsive pad-20' data={tableData} columns={cols} 
    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found'
    pagination={options} />
        </Row>
        <Dialog open={showModal} onClose={handleClose} maxWidth='lg'>
        <DialogTitle>Review</DialogTitle>
        <DialogContent>
          <TextField value={rowData && rowData.mandap_name}
            margin="dense" disabled
            id="mandap_id_id"
            label="Mandap Name"
            type="text"
            fullWidth
            variant="standard"
          />
          <textarea value={rowData && rowData.comments}
            className="form-control mar-t-10"
            cols={70}
            rows={7}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default Reviews;

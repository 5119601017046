import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import Loader from "../../Helper/Loader";
import empty from "is-empty";
import Select from "react-select";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import useDebounce from "../../CustomHooks/useDebounce";

const CashbackForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  let propsState = props.history.location.state;
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [booking, setbooking] = useState([]);
  const [bookingList, setbookingList] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [vendorList, setvendorList] = useState([]);
  const [selectDropDownString, setSelectDropDownString] = useState("");
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  let breadcrumbData = [
    {
      title: "Cashback",
      href: "/cashback",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Cashback Details",
      id: "details",
      show: true,
    },
  ]);

  const handleSelect = (keyName, setFn, e) => {
    if (keyName === "vendor") {
      if (e && e.value) {
        let booking = [];
        setLoader(true);
        get(config.service + "/booking/base/list?&vendor_id=" + e.value, {
          headers: tokenKey,
        })
          .then((resp) => {
            resp.data.map((obj) => {
              booking.push({ value: obj.id, label: obj.booking_no });
            });
            setLoader(false);
          })
          .catch((err) => setLoader(false));
        setbookingList(booking);
      } else setbookingList([]);
      setbooking(null);
    }
    setFn(e);
    setForm({
      ...form,
      [keyName]: (e && e.value) || null,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    history.push("/cashback");
  };

  const findErrors = () => {
    const { amount } = form;
    const newErrors = {};
    if (empty(booking)) newErrors.booking = "This is a required field";
    if (empty(vendor)) newErrors.vendor = "This is a required field";
    if (empty(amount)) newErrors.amount = "This is a required field";

    return newErrors;
  };

  const saveForm = () => {
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let data = {
          booking: booking.value,
          vendor: vendor.value,
          amount: form.amount,
        },
        id = editMode ? propsState.id : null,
        method = editMode ? "patch" : "post",
        url = editMode
          ? config.service + "/vendor/cashback/" + id + "/"
          : config.service + "/vendor/cashback/create/";
      axios({
        url: url,
        method: method,
        headers: tokenKey,
        data: data,
      })
        .then((res) => {
          if (editMode) setForm(res.data);
          toast.success("Saved successfully", toastAttr);
          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else if (err.response.data && err.response.data.message)
            toast.error(
              err.response.data.message[0] || "Saving Failed",
              toastAttr
            );
          else toast.error("Saving Failed", toastAttr);
          setLoader(false);
        });
    }
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  useEffect(() => {
    get(config.service + "/masters/base/vendor/", { headers: tokenKey })
      .then((res) => {
        let vendor = [],
          booking = [];
        res.data.map((obj) => {
          vendor.push({ value: obj.id, label: obj.vendor_name });
        });

        // setvendorList(vendor);
        if (propsState && propsState.editMode)
          get(config.service + "/vendor/cashback/" + propsState.id + "/", {
            headers: tokenKey,
          }).then((res) => {
            get(
              config.service +
                "/booking/base/list/?&vendor_id=" +
                res.data.vendor,
              { headers: tokenKey }
            ).then((resp) => {
              resp.data.map((obj) => {
                booking.push({ value: obj.id, label: obj.booking_no });
              });
              setbookingList(booking);
              let vendorVal = vendor.filter(
                (temp) => temp.value === res.data.vendor
              );
              let bookingVal = booking.filter(
                (temp) => temp.value === res.data.vendor
              );
              setVendor(vendorVal);
              setbooking(bookingVal);
              setForm(res.data);
              setLoader(false);
            });
          });
        else setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/";
        setLoader(false);
      });
  }, []);

  const handleInputChange = (event) => {
    setSelectDropDownString(event);
  };

  // useDebounce hook for dropDown menu
  const debouncedValue = useDebounce(selectDropDownString, 500);
  const search = useCallback(async () => {
    get(
      config.service + `/masters/base/vendor/?&search=${debouncedValue}`,
      tokenKey
    ).then((res) => {
      let vendors = [];
      res?.data?.results?.map((obj) => {
        vendors.push({ value: obj.id, label: obj.vendor_name });
      });
      setvendorList(vendors);
    });
  }, [debouncedValue]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv">
                <Col md={12}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              <Row>
                                <Col md={6}>
                                  <Row>
                                    <Col md={4}>
                                      <label>Vendor</label>
                                    </Col>
                                    <Col md={7}>
                                      <Select
                                        className={`selectBox ${
                                          !!errors.vendor ? "inValidField" : ""
                                        }`}
                                        value={vendor}
                                        onChange={(e) =>
                                          handleSelect("vendor", setVendor, e)
                                        }
                                        options={vendorList}
                                        onInputChange={handleInputChange}
                                        isClearable
                                      />
                                      {errors.vendor ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors.vendor}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label>Booking</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Select
                                        className={`selectBox ${
                                          !!errors.booking ? "inValidField" : ""
                                        }`}
                                        value={booking}
                                        onChange={(e) =>
                                          handleSelect("booking", setbooking, e)
                                        }
                                        options={bookingList}
                                        isClearable
                                      />
                                      {errors.booking ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors.booking}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label>Amount</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="number"
                                        name="amount"
                                        value={form.amount}
                                        onChange={(e) =>
                                          handleInput("amount", e.target.value)
                                        }
                                        isInvalid={!!errors.amount}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.amount}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default CashbackForm;

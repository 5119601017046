/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "react-bootstrap";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import empty from "is-empty";
import { post, get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import ResetPassword from "../components/ResetPasswordForm";

const LogInForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showResetPwd, setshowResetPwd] = useState(false);
  const [showErrors, setshowErrors] = useState(false);
  const [err, setErr] = useState([]);
  let history = useHistory();
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  function onLoginSubmit() {
    let data = {
      username: username,
      password: password,
    };
    if (empty(username) || empty(password))
      toast.error("Enter username and password", toastAttr);
    else
      post(config.service + "/rest-auth/login/", data)
        .then((res) => {
          sessionStorage.setItem("tokenKey", res.data.key);
          sessionStorage.setItem("userName", username);
          get(config.service + "/accounts/user_role/", {
            headers: { Authorization: "Token " + res.data.key },
          }).then((res) => {
            sessionStorage.setItem("permission", JSON.stringify(res.data));
            history.push("/dashboard");
          });
        })
        .catch((err) => {
          toast.error("Login Failed", toastAttr);
          if (
            err.response &&
            err.response.data &&
            err.response.data.non_field_errors
          ) {
            setErr(err.response.data.non_field_errors);
            setshowErrors(true);
          }
        });
  }
  // window.onunload = function () {
  //   sessionStorage.removeItem('tokenKey');
  // }

  const functioforCrmdashboard = () => {
    Cookies.remove("tokenKey");
    let data = {
      username: "admin1",
      password: "admin@bookwedgo_1234",
    };
    post(config.service + "/rest-auth/login/", data)
      .then((res) => {
        sessionStorage.setItem("tokenKey", res.data.key);
        sessionStorage.setItem("userName", username);
        get(config.service + "/accounts/user_role/", {
          headers: { Authorization: "Token " + res.data.key },
        }).then((res) => {
          sessionStorage.setItem("permission", JSON.stringify(res.data));
          history.push("/dashboard");
        });
      })
      .catch((err) => {
        toast.error("Lo Failed", toastAttr);
      });
  };

  useEffect(() => {
    const token = Cookies.get("tokenKey");
    if (token !== undefined) {
      // sessionStorage?.setItem("tokenKey", token);
      // Cookies.remove("tokenKey");
      functioforCrmdashboard();
    }
  }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onLoginSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [username, password]);

  const handleClose = () => {
    setErr([]);
    setshowErrors(false);
  };

  return (
    <form className="form loginForm">
      <div className="form__form-group">
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <input
            type="text"
            name="name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Name"
          />
        </div>
      </div>
      <div className="form__form-group">
        {/* <span className="form__form-group-label">Password</span> */}
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          {/* <Field
              name="password"
              component={PasswordField}
              placeholder="Password"
              className="input-without-border-radius"
              keyIcon
            /> */}
          {/* <div className="account__forgot-password">
              <NavLink to="/reset_password">Forgot password?</NavLink>
            </div> */}
        </div>
      </div>
      <div className="loginBtns">
        <Button variant="primary" onClick={(e) => onLoginSubmit()}>
          Sign In
        </Button>
        <a className="forgotPwd" onClick={(e) => setshowResetPwd(true)}>
          Forgot Password
        </a>
      </div>

      {showResetPwd ? (
        <ResetPassword
          username={username}
          handleClose={(e) => {
            setshowResetPwd(false);
          }}
        />
      ) : (
        ""
      )}
      {showErrors ? (
        <Dialog open={showErrors} onClose={handleClose} maxWidth="lg">
          <DialogTitle>Errors</DialogTitle>
          <DialogContent>
            <ul className="errorList">
              {err.length > 0 &&
                err.map((errStr) => {
                  return <li className="err">{errStr}</li>;
                })}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </form>
  );
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default LogInForm;

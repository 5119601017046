import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Button } from "react-bootstrap";
import empty from "is-empty";
import axios, { get } from "axios";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import { HiRefresh, HiOutlineTrash } from "react-icons/hi";
import CustomSwitch from "../../../Helper/CustomSwitch";
import config from "../../../../config";
import Loader from "../../../Helper/Loader";
import { ToastContainer, toast } from "react-toastify";

const Photos = (props) => {
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  const [images, setImages] = useState([]);
  const [imgCategoryList, setImgCategoryList] = useState([]);
  const [imgCategory, setImgCategory] = useState({});
  const [check, setcheck] = useState(0);
  const maxNumber = 100;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const onChangeImage = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(images);
    setcheck(check + 1);
    props.save(images);
    imageList?.map((obj, index) => {
      obj["image_name"] = `${props?.mandap?.mandap_name}_${index}`;
    });
    setImages(imageList);
    props.save(imageList);
  };

  const onModifyImage = (imgIndex) => {
    images.map((obj, index) => {
      if (index === imgIndex) {
        obj.image_name = "";
        obj.image_category_id = "";
        obj.title = "";
        obj.description = "";
      }
    });
    setImgCategory({
      ...imgCategory,
      ["cat_" + imgIndex]: null,
    });
    setImages(images);
    setcheck(check + 1);
    props.save(images);
  };

  const removeImage = (callback, imgIndex, imgId) => {
    function clearImg() {
      let imgCat = imgCategory,
        catKeys = Object.keys(imgCat);
      catKeys.map((obj, index) => {
        if (index === catKeys.length - 1 && index !== imgIndex) return;
        else if (index >= imgIndex)
          imgCat["cat_" + index] = imgCat["cat_" + (index + 1)];
      });
      delete imgCat["cat_" + (catKeys.length - 1)];
      setImgCategory(imgCat);
      let temp = images.filter((obj, index) => index !== imgIndex);
      //   images.map((obj,index)=>{
      //     if(index === imgIndex){
      //         delete images[index];
      //     }
      // })
      setImages(temp);
      props.save(images);
      callback(imgIndex);
      setcheck(check + 1);
    }
    if (imgId) {
      let data = { id: [imgId] };
      axios
        .delete(config.service + "/masters/vendor_images/delete/", {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("tokenKey"),
          },
          data: {
            id: [imgId],
          },
        })
        .then((res) => {
          clearImg();
          toast.success("Image removed successfully", toastAttr);
        })
        .catch((err) => {
          toast.error("Image removal failed", toastAttr);
        });
    } else clearImg();
  };

  const removeAllImages = (callback) => {
    let ids = [];
    images.map((obj, index) => {
      if (obj.id) ids.push(obj.id);
    });
    if (ids.length > 0)
      axios
        .delete(config.service + "/masters/vendor_images/delete/", {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("tokenKey"),
          },
          data: {
            id: ids,
          },
        })
        .then((res) => {
          callback();
          toast.success("All Images are removed", toastAttr);
        })
        .catch((err) => {
          toast.error("Image removal failed", toastAttr);
        });
    else {
      callback();
      toast.success("All Images are removed", toastAttr);
    }
    setImgCategory({});
    setImages([]);
    setcheck(check + 1);
  };

  const handleImageDetails = (keyName, e, imgIndex) => {
    images.map((obj, index) => {
      if (index === imgIndex) {
        obj[keyName] = e.target.value;
      }
    });
    setImages(images);
    setcheck(check + 1);
    props.save(images);
  };

  const handleSelect = (keyName, e, imgIndex) => {
    // if(e){
    setImgCategory({
      ...imgCategory,
      ["cat_" + imgIndex]: e || null,
    });
    images.map((obj, index) => {
      if (index === imgIndex) {
        obj["image_category_id"] = (e && e.value) || null;
      }
    });
    setImages(images);
    // }
  };

  const handleCheckBox = (keyName, value, imgIndex) => {
    images.map((obj, index) => {
      if (index === parseInt(imgIndex) && value) {
        obj[keyName] = value;
      } else obj[keyName] = false;
    });
    setImages(images);
    setcheck(check + 1);
    props.save(images);
  };

  useEffect(() => {
    let data = [];
    get(config.service + "/masters/image_category/", tokenKey)
      .then((res) => {
        for (let i in res.data)
          data.push({
            label: res.data[i].category_name,
            value: res.data[i].id,
          });
        setImgCategoryList(data);
        get(
          config.service +
            "/masters/vendor_images/?&vendor_mandap_id=" +
            props.mandapId,
          tokenKey
        ).then((res) => {
          setImages(res.data);
          props.save(res.data);
          if (props.propsData && props.propsData.editMode) {
            let imgCat = {};
            res.data.map((obj, index) => {
              let catVal = data.filter(
                (temp) => temp.value === obj.image_category_id
              );
              imgCat = {
                ...imgCat,
                ["cat_" + index]: catVal[0],
              };
            });
            setImgCategory({
              ...imgCategory,
              ...imgCat,
            });
            props.setLoader(false);
          } else props.setLoader(false);
        });
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        props.setLoader(false);
      });
  }, []);

  return (
    <Row>
      <Col md={9}>
        <Loader loading={props.loading} overlay={props.overlay}>
          <form encType="multipart/form-data">
            <div className="multiImgUpload">
              {images && images.length >= 0 ? (
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChangeImage}
                  maxNumber={maxNumber}
                  dataURLKey="image"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div className="upload__image-wrapper">
                      <Button
                        variant="primary"
                        style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </Button>
                      &nbsp;
                      <Button
                        variant="primary"
                        disabled={empty(imageList) ? true : false}
                        onClick={() => removeAllImages(onImageRemoveAll)}
                      >
                        Remove all images
                      </Button>
                      {imageList?.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.image} alt="photo" width="100" />
                          <div className="image-item__btn-wrapper">
                            <input
                              type="text"
                              className="mar-b-5"
                              value={`${props?.mandap?.mandap_name}_${index}`}
                              placeholder="Image name"
                              // onChange={(e) =>
                              //   handleImageDetails("image_name", e, index)
                              // }
                            />

                            <Select
                              className="selectBox mar-b-5"
                              value={imgCategory["cat_" + index] || null}
                              onChange={(e) =>
                                handleSelect("imgCategory", e, index)
                              }
                              options={imgCategoryList}
                              isClearable
                            />

                            <input
                              type="text"
                              className="mar-b-5"
                              value={image.title}
                              placeholder="Title"
                              onChange={(e) =>
                                handleImageDetails("title", e, index)
                              }
                            />
                            <input
                              type="text"
                              className="mar-b-5"
                              value={image.description}
                              placeholder="Description"
                              onChange={(e) =>
                                handleImageDetails("description", e, index)
                              }
                            />
                            <div className="pad-t-5">
                              <CustomSwitch
                                handleChange={handleCheckBox}
                                name="cover_image"
                                data={index.toString()}
                                value={image.cover_image}
                              />{" "}
                              Cover Image
                            </div>
                            <div className="imgActions">
                              <HiRefresh
                                className="color-green"
                                onClick={() => {
                                  onImageUpdate(index);
                                  onModifyImage(index);
                                }}
                              />
                              <HiOutlineTrash
                                className="themeColor"
                                onClick={(e) =>
                                  removeImage(onImageRemove, index, image.id)
                                }
                              />
                              <span style={{ display: "none" }}>{check}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              ) : (
                ""
              )}
            </div>
          </form>
        </Loader>
      </Col>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  );
};

export default Photos;

import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Container } from "reactstrap";
// import CryptoJS from "crypto-js";
// import Cookies from "js-cookie";
import { Form } from "react-bootstrap";
import Vendor from "./views/vendor";
import Booking from "./views/booking";
import { get } from "axios";
import config from "../../../config";
import Breadcrumbs from "../../Helper/Breadcrumbs";

const Dashboard = () => {
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let role = sessionStorage.getItem("permission")
    ? JSON.parse(sessionStorage.getItem("permission"))[0].user_role
    : "agent";
  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "#",
    },
  ];
  const [indexVal, setIndexVal] = useState(0);
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(true);
  const [tab, setTab] = useState({
    vendor: false,
    booking: false,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Vendor",
      id: "vendor",
      show: role === "admin" || role === "agent",
    },
    {
      name: "Bookings",
      id: "booking",
      show: role === "admin" || role === "vendor",
    },
  ]);

  const handleTab = (e, index) => {
    let tabValue = e.target.id;
    setIndexVal(index);
    setTab({
      vendor: false,
      booking: false,
      [tabValue]: true,
    });
  };

  const setLoader = (val) => {
    setloading(val);
  };

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window?.location?.search);
  //   const encryptedToken = queryParams?.get("z");
  //   const crmleadId = queryParams?.get("lead");
  //   const role = queryParams?.get("role");
  //   console.log(queryParams, "hello");

  //   if (encryptedToken) {
  //     const secretKey = "adminKey";
  //     const bytes = CryptoJS.AES.decrypt(
  //       decodeURIComponent(encryptedToken),
  //       secretKey
  //     );
  //     const token = bytes.toString(CryptoJS.enc.Utf8);
  //     if (token) {
  //       Cookies.set("tokenKey", token, { expires: 1 });
  //       localStorage.removeItem("crm_lead_id");
  //       localStorage.setItem("crm_lead_id", crmleadId);
  //       localStorage.removeItem("role");
  //       localStorage.setItem("role", role);
  //     } else {
  //       console.log("error");
  //     }
  //   }
  // }, []);

  useEffect(() => {
    let vendor = false,
      booking = false;
    if (role === "admin" || role === "agent") vendor = true;
    else if (role === "admin" || role === "vendor") booking = true;
    setTab({
      vendor: vendor,
      booking: booking,
    });
    get(config.service + "/masters/dashboard/", tokenKey)
      .then((res) => {
        setdata(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            {/* <SearchFilter/> */}
            <CardBody>
              <div className="card__title">
                <h4 className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </h4>
                <Row className="formDiv dbCont">
                  <Col md={12}>
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <div className="tabList">
                          {tabData.map((tab, index) => {
                            let disCls = tab.disable;
                            return tab.show ? (
                              <a
                                className={`nav-item nav-link ${
                                  indexVal === index ? "active" : ""
                                }`}
                                id={tab.id}
                                name={index}
                                data-toggle="tab"
                                role="tab"
                                aria-controls="nav-details"
                                aria-selected="true"
                                onClick={(e) => handleTab(e, index)}
                              >
                                {tab.name}
                              </a>
                            ) : (
                              ""
                            );
                          })}
                        </div>
                      </div>
                    </nav>
                    <div className="tabContent">
                      <Form className="form defaultForm">
                        {tab.vendor && data ? (
                          <Vendor data={data} loading={loading} />
                        ) : (
                          ""
                        )}
                        {tab.booking && data ? (
                          <Booking data={data} loading={loading} />
                        ) : (
                          ""
                        )}
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;

import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {BiTargetLock} from 'react-icons/bi';
import {MdQrCodeScanner,MdRateReview} from 'react-icons/md';
import {FaCalendarCheck,FaRegFileAlt} from 'react-icons/fa'
import {FaTasks,FaMoneyCheckAlt} from 'react-icons/fa'

const Masters = () => {
  let history = useHistory();

  const redirectTo = path => {
    history.push('/settings/'+path)
  }

 return(
    <Container className="masters">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
      <div className="card__title pageNav">
          <div className="bold-text themeColor pad-l-10">Manage</div>
        </div>
        <Row style={{padding : '0 20px 10px 0'}}>
          <Col md={12}>
            <Row>
              <Col md={2} className='m_col'>
                <div className='m_icon'><MdQrCodeScanner style={{color : 'black'}} onClick={e=>redirectTo('qrcode')} /><div>Assign QR Code</div></div>
              </Col>
              <Col md={2} className='m_col'>
              <div className='m_icon'><BiTargetLock style={{color : '#F55c7A'}} onClick={e=>redirectTo('target')} /><div>Set Targets</div></div>
              </Col>
              <Col md={2} className='m_col'>
                <div className='m_icon'><FaCalendarCheck style={{color : 'green'}} onClick={e=>redirectTo('updateDates')} /><div>Update Dates</div></div>
              </Col>
              <Col md={2} className='m_col'>
                <div className='m_icon'><FaRegFileAlt style={{color : 'blue'}} onClick={e=>redirectTo('updateContent')} /><div>Update Content</div></div>
              </Col>
              <Col md={2} className='m_col'>
                <div className='m_icon'><MdRateReview style={{color : 'red'}} onClick={e=>redirectTo('reviews')} /><div>Reviews</div></div>
              </Col>
              
              {/* <Col md={2} className='m_col'>
              <div className='m_icon'><FaTasks style={{color : 'cornflowerblue'}} onClick={e=>redirectTo('')} /><div>Assign Tasks</div></div>
              </Col> */}
            </Row>
            
          </Col>
        </Row>
          </CardBody>
          </Card>
          </Col>
          </Row>
          </Container>
 )
}

export default Masters;
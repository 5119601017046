import React, {useState,useEffect} from 'react'
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config';
import Loader from '../../../Helper/Loader'
import empty from 'is-empty'
import Select from 'react-select'
import Breadcrumbs from '../../../Helper/Breadcrumbs'
import Blog from './blog';


const UpdateContent = props => {
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
    let breadcrumbData = [
        {
          title : 'Manage',
          href : '/settings'
        },
        {
          title : 'Update Content',
          href : '#'
        }
      ]
    let toastAttr = {
        theme: "colored",
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        };

    const [loading, setloading] = useState(false);
    const [overlay, setoverlay] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [heading, setHeading] = useState(null);
    const [blogTableData, setBlogTableData] = useState([]);
    const [mandapList, setMandapList] = useState([]);
    const [selId, setselId] = useState(null);

    let headingList = [{label:'Disclaimer',value:'disclaimer'},{label:'About us',value:'about_us'}]


    const setLoader = (val) => {
        setloading(val);setoverlay(val);
        }

    const handleSelect = (keyName,setFn,e) =>{
        setLoader(true);
        if(e.value === 'blog'){
            get(config.service+'/masters/blog/',{headers:tokenKey}).then(res=>{
                setBlogTableData(res.data);
                get(config.service+'/masters/base/vendor-mandap/',{headers:tokenKey}).then(res=>{
                    let mandap =[];
                    res.data.map(obj=>{
                        mandap.push({id:obj.id,name:obj.mandap_name});
                    })
                    setMandapList(mandap);
                    setFn(e);
                    setForm({
                        ...form,
                        [keyName] : (e && e.value) || null
                    })
                    if (keyName && !!errors[keyName]) setErrors({
                        ...errors,
                        [keyName]: null
                    })
                    setLoader(false);
                  })
            }).catch(err=>{
                if(err.response.status === 401) window.location.href = '/';
                setLoader(false);
            })
        } else{
            get(config.service+'/masters/page-content/?&search='+e.value,{headers:tokenKey}).then(res=>{
                setFn(e);
                setForm({
                    ...form,
                    content : res.data && res.data[0] && res.data[0].content || '',
                    [keyName] : (e && e.value) || null
                })
                setselId(res.data && res.data[0] && res.data[0].id || null)
                if (keyName && !!errors[keyName]) setErrors({
                    ...errors,
                    [keyName]: null
                })
                setLoader(false);
            }).catch(err=>{
                if(err.response.status === 401) window.location.href = '/';
                setLoader(false);
            })
        }
    }

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }

    const updateContent = () => {
        if(heading && heading.value !== 'blog' ){
            setLoader(true);
            let postData = {
                heading : heading.label,
                content: form.content,
                type:heading.value
            };
            axios({
                url: selId ? config.service+'/masters/page-content/'+selId+'/' : config.service+'/masters/page-content/',
                method: selId ? 'put' : 'post',
                data: postData,
                headers: {Authorization:'Token '+sessionStorage.getItem('tokenKey')},
              }).then(res=>{
                setLoader(false);
                toast.success('Updated successfully', toastAttr)
            }).catch(err=>{
                toast.error('Updation failed', toastAttr)
                setLoader(false);
                if(err.response.status === 401) window.location.href = '/'; 
            });
        }
    }

    const triggerTableData = () => {
        setLoader(true);
        get(config.service+'/masters/blog/',{headers:tokenKey}).then(res=>{
            setBlogTableData(res.data);
            setLoader(false)
          }).catch(err=>{
            if(err.response.status === 401) window.location.href = "/"
            setLoader(false)
          })
    }

    return(
        <Container className="vendor">
        <Row>
      <Col md={12} className='contBody'>
        <Card>
          <CardBody className='theme-light'>
            <div className="card__title pageNav">
              <div className="bold-text">
              <Breadcrumbs data={breadcrumbData} />
              </div>
            </div>
            <Row className='formBtnsDiv'>
                <Col md={12}>
                        <Button variant='primary' disabled={heading && heading.value === 'blog'} onClick={updateContent}>Save</Button> 
                </Col>
            </Row>
            <Loader loading={loading} overlay={overlay}>
            <Row className='pad-t-10'>
                <Col md={8}>
                    <Row>
                        <Col md={4}>Heading</Col>
                        <Col md={8}>
                            <Select className={`selectBox ${!! errors.heading  ? 'inValidField' : ''}`}
                                value={heading} 
                                onChange={e=>handleSelect('heading',setHeading,e)}
                                options={headingList}
                            />          
                            {errors.heading  ?                                      
                                <Form.Control.Feedback type='invalid'>
                                    { errors.heading }
                                </Form.Control.Feedback> : ''}
                        </Col>
                    </Row>
                    {heading && heading.value !== 'blog' ?
                    <Row className='pad-t-10'>
                        <Col md={4}>Content</Col>
                        <Col md={8}>
                            <Form.Control as='textarea' rows={10} name="content" value={form.content} onChange={e=> handleInput('content',e.target.value)} />
                            {errors.content  ?                                      
                                <Form.Control.Feedback type='invalid'>
                                    { errors.content }
                                </Form.Control.Feedback> : ''}
                        </Col>
                    </Row> : ''}
                </Col>
            </Row>
            {heading && heading.value === 'blog' ?
            <Row>
                <Col md={12} className='pad-t-10'>
                    <Blog data={blogTableData} mandapList={mandapList} setLoader={setLoader} triggerTableData={triggerTableData} />
                </Col>
            </Row> : ''}
            </Loader>
          </CardBody>
        </Card>
      </Col>
      </Row>
      <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
      </Container>
    )
}


export default UpdateContent;
import React, {  useEffect, useMemo, useState } from "react";
// import { uniq } from "lodash";
import PropTypes from 'prop-types'; 
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "./style.css";


export const CustomClientSideGrid = (props) => {
  const {
    rowData,
    columnDefs,
    getSelectedValues = () => {},
    tableHeight,
    getGridAPI = () => {},
    getFilteredRows = () => {}
    
    

  } = props;
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    getGridAPI(params.api)
    params.api.sizeColumnsToFit();
  };
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      resizable:true,
      filter: true,
      // flex: 1,
      // minWidth: 100,
    };
  }, []);
//_____________________GET SELECTED CHECKBOX VALUES___________________________//
  const onSelectionChanged = () => {
    const selectedNodes = gridApi.getSelectedNodes();
   
    const selectedData = selectedNodes.map((node) => node.data);
    const uncheckedNodes = gridApi.rowModel.rowsToDisplay.filter(
      (node) => node.selected===false
    );
  
    const uncheckedData = uncheckedNodes.map((node) => node.data);
    getSelectedValues(selectedData,uncheckedData); // getSelectedValues fun() is sent the data for child to parent func()
     };
     const paginationPageSizeSelector = useMemo(() => {
        return [10,20, 50, 100];
      }, []);


      const onFilterChanged=(event)=>{
     
        getFilteredRows(event.api.rowModel.rowsToDisplay.length )
      

      }
    

  const gridOptions = {
    onSelectionChanged,
    onFilterChanged,

  };


  

  return (
    <div
      className="ag-theme-alpine"
      style={{ width: "100%", height: tableHeight }}
    >
      <AgGridReact
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows
        gridOptions={gridOptions}
        overlayLoadingTemplate={
          '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
        }
        paginationPageSizeSelector={paginationPageSizeSelector}
        paginationPageSize={10}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        pagination={true}
        suppressRowClickSelection
      />
    </div>
  );
};
CustomClientSideGrid.propTypes = { 
    rowData: PropTypes.array.isRequired ,
    columnDefs: PropTypes.array.isRequired ,
    tableHeight:PropTypes.string.isRequired,
    getSelectedValues:PropTypes.func,
    getGridAPI: PropTypes.func,
    getFilteredRows:PropTypes.func


};

import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { BsFillPersonPlusFill, BsFillPersonCheckFill } from "react-icons/bs";

import { useHistory } from "react-router-dom";
import {
  FaCity,
  FaLandmark,
  FaChartArea,
  FaHome,
  FaListUl,
  FaRegImages,
  FaIdCardAlt,
  FaBlogger,
} from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { FaMoneyCheck } from "react-icons/fa";

import { BiSolidOffer } from "react-icons/bi";
import { TiThLargeOutline } from "react-icons/ti";
import { BiMoney, BiMerge } from "react-icons/bi";
import { GiCampingTent } from "react-icons/gi";
import {
  MdFeedback,
  MdOutlineDeveloperBoard,
  MdOutlineAddHomeWork,
} from "react-icons/md";
import { ImPriceTags } from "react-icons/im";
import { FcFaq, FcPlanner } from "react-icons/fc";
import { BsPostcard } from "react-icons/bs";

const Masters = () => {
  let history = useHistory();

  const redirectTo = (path) => {
    history.push("/masters/" + path);
  };

  return (
    <Container className="masters">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text themeColor pad-l-10">Masters</div>
              </div>
              <Row style={{ padding: "0 20px 10px 0" }}>
                <Col md={12}>
                  <Row>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaLandmark
                          style={{ color: "#00B0FC" }}
                          onClick={(e) => redirectTo("state")}
                        />
                        <div>State</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaCity
                          style={{ color: "#F55c7A" }}
                          onClick={(e) => redirectTo("city")}
                        />
                        <div>City</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaChartArea
                          style={{ color: "#87ceeb" }}
                          onClick={(e) => redirectTo("area")}
                        />
                        <div>Area</div>
                      </div>
                    </Col>

                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaHome
                          style={{ color: "#ff4dff" }}
                          onClick={(e) => redirectTo("mandaptype")}
                        />
                        <div>Mandap Type</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <BiMerge
                          style={{ color: "#00FFFF" }}
                          onClick={(e) => redirectTo("mandapclassification")}
                        />
                        <div>Mandap Classification</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaRegImages
                          style={{ color: "#00008B" }}
                          onClick={(e) => redirectTo("imagecategory")}
                        />
                        <div>Image Category</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaListUl
                          style={{ color: "#0047AB" }}
                          onClick={(e) => redirectTo("vendorcategory")}
                        />
                        <div>Vendor Category</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <TiThLargeOutline
                          style={{ color: "#617C58" }}
                          onClick={(e) => redirectTo("typeofconcern")}
                        />
                        <div>Type Of Concern</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <BiMoney
                          style={{ color: "forestgreen" }}
                          onClick={(e) => redirectTo("quotationtype")}
                        />
                        <div>Quotation Type</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaIdCardAlt
                          style={{ color: "#737CA1" }}
                          onClick={(e) => redirectTo("idproof")}
                        />
                        <div>Id Proof</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <MdFeedback
                          style={{ color: "#38ACEC" }}
                          onClick={(e) => redirectTo("feedback")}
                        />
                        <div>FeedBack</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <GiCampingTent
                          style={{ color: "#3C565B" }}
                          onClick={(e) => redirectTo("campaign")}
                        />
                        <div>Campaign</div>
                      </div>
                    </Col>
                    {/* <Col md={2} className='m_col'>
                <div className='m_icon'><MdEventAvailable style={{color:'#CCCCFF'}} onClick={e=>redirectTo('mandapavailabilityForm')} /><div>Mandap Availability</div></div>
              </Col> */}
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <MdOutlineDeveloperBoard
                          style={{ color: "#FA8072" }}
                          onClick={(e) => redirectTo("functiontype")}
                        />
                        <div>FunctionType</div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <ImPriceTags
                          onClick={(e) => redirectTo("priceDetails")}
                        />
                        <div>Price Details </div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <MdOutlineAddHomeWork
                          style={{ color: "navy" }}
                          onClick={(e) => redirectTo("mandapTypeSeo")}
                        />
                        <div>mandap type seo </div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FcFaq
                          style={{ color: "navy" }}
                          onClick={(e) => redirectTo("faq")}
                        />
                        <div>FAQ </div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaBlogger
                          style={{ color: "yellow" }}
                          onClick={(e) => redirectTo("blogPost")}
                        />
                        <div>Blog </div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <BsPostcard
                          style={{ color: "blue" }}
                          onClick={(e) => redirectTo("post")}
                        />
                        <div>Posts </div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FcPlanner
                          style={{ color: "#FF8000" }}
                          onClick={(e) => redirectTo("MuhurthaCalandar")}
                        />
                        <div>Muhurtham Calendar </div>
                      </div>
                    </Col>

                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <BsFillPersonPlusFill
                          style={{ color: "#ed4a4a" }}
                          onClick={(e) => redirectTo("users")}
                        />
                        <div>Add Users </div>
                      </div>
                    </Col>

                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <BsFillPersonCheckFill
                          style={{ color: "black" }}
                          onClick={(e) => redirectTo("userlogs")}
                        />
                        <div>User Logs </div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FcApproval onClick={(e) => redirectTo("discounts")} />
                        <div>Discounts </div>
                      </div>
                    </Col>
                    <Col md={2} className="m_col">
                      <div className="m_icon">
                        <FaMoneyCheck
                          style={{ color: "blue" }}
                          onClick={(e) => redirectTo("razorpaylogs")}
                        />
                        <div>RazorPay Details </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Masters;

import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config';
import Loader from '../../../Helper/Loader'
import empty from 'is-empty'
import Select from 'react-select'
import Breadcrumbs from '../../../Helper/Breadcrumbs'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const TargetForm = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(propsState && propsState.editMode || false);
  const [errors, setErrors] = useState({});
  const [role, setRole] = useState([]);
  const [roleList, setRoleList] = useState([{value:'agent',label:'Agent'},{value:'vendor',label:'Vendor'}]);
  const [user, setUser] = useState([]);
  const [userList, setUserList] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [vendorList, setvendorList] = useState([]);
  const [mandap, setMandap] = useState(null);
  const [mandapList, setMandapList] = useState([]);
  const [targetMonth, setTargetMonth] = useState(null)
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  let breadcrumbData = [
    {
      title : 'Manage',
      href : '/settings'
    },{
      title : 'Target',
      href : '/settings/target'
    },{
      title : 'Set',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tab, setTab] = useState({
      details:true,
  });
    const [tabData, setTabData] = useState([
        {
            name:'Target Details',
            id:'details',
            show:true
        }
    ]);

    const handleSelect = (keyName,setFn,e) =>{
        if(e && e.value === 'agent' && empty(userList)){
            setLoader(true)
            get(config.service+'/accounts/user/list/',{headers:tokenKey}).then(res=>{
                let user =[];
                res.data.map(obj=>{
                    if(obj.user_role === 'agent'){
                        let name = obj.user_id.username;
                        user.push({value:obj.id,label:name});
                    }
                })
                setUserList(user);
                setVendor(null);setMandap(null);
                setLoader(false);
              }).catch(err=>{
                if(err.response.status === 401) window.location.href = '/'
                setLoader(false);
              })
        } else if(e && e.value === 'vendor' && empty(vendorList)){
            setLoader(true)
            get(config.service+'/masters/base/vendor/',{headers:tokenKey}).then(res=>{
                let vendor =[];
                res.data.map(obj=>{
                    vendor.push({value:obj.id,label:obj.vendor_name});
                })
                setvendorList(vendor);setMandap(null);setUser(null);
                setLoader(false);
              }).catch(err=>{
                if(err.response.status === 401) window.location.href = '/'
                setLoader(false);
              })
        } else if(keyName === 'vendor'){
            setLoader(true);
            if(e && e.value)
              get(config.service+'/masters/base/vendor-mandap/?&vendor_id='+e.value,{headers:tokenKey}).then(res=>{
                  let mandap =[];
                  res.data.map(obj=>{
                      mandap.push({value:obj.id,label:obj.mandap_name});
                  })
                  setMandapList(mandap);
                  setLoader(false);
                }).catch(err=>{
                  if(err.response.status === 401) window.location.href = '/'
                  setLoader(false);
                })
            else {
              setMandapList([]);setMandap(null);
                  setLoader(false);
            }
        }
        setFn(e);
        setForm({
            ...form,
            [keyName] : (e && e.value) || null
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


  const renderListView = () => {
      history.push("/settings/target")
  }

  const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }

  const findErrors = () => {
    // const { role,user,vendor } = form
    const newErrors = {};
    // if ( empty(role) ) newErrors.role = 'This is a required field';
    // if ( empty(user) ) newErrors.user = 'This is a required field';
    // if ( empty(vendor) ) newErrors.vendor = 'This is a required field';

    return newErrors;
   }

  const saveForm = () => {
    const newErrors = findErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors)
      toast.error('Enter all required details to proceed', toastAttr);
    } else {
      setLoader(true);
        let data = {
            "month": targetMonth.getMonth() + 1,
            "year": targetMonth.getFullYear(),
            "target": form.target,
        }
        if(role.value === 'agent') data.user_id = user.value;
        else {
            data.vendor_id = vendor.value;
            data.mandap_id = mandap.value;
        }
        axios({
            url: config.service+'/masters/targetmaster/create',
            method: 'post',
            headers: tokenKey,
            data: data,
          }).then(res=>{
              if(editMode) setForm(res.data)
              toast.success('Saved successfully', toastAttr);
              setLoader(false);
        }).catch(err=>{
          if(err.response.status === 401) window.location.href = '/'
          else
            toast.error('Saving Failed', toastAttr);
            setLoader(false);
        })
    }
  }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }


  useEffect(() => {
    if(propsState && propsState.editMode){
      get(config.service+'/masters/targetmaster/'+propsState.id+'/',{headers:tokenKey}).then(res=>{
        setForm(res.data);
        let data = res.data;
        if(data.role === 'agent'){
          get(config.service+'/accounts/user/list/',{headers:tokenKey}).then(res=>{
              let user =[];
              res.data.map(obj=>{
                  if(obj.user_role === 'agent'){
                      let name = obj.user_id.username;
                      user.push({value:obj.id,label:name});
                  }
              })
              let roleVal = roleList.filter(temp=>temp.value === data.role);
              let userVal = user.filter(temp=>temp.value === data.user_id);
              setRole(roleVal);
              setUser(userVal);
              setUserList(user);
              setLoader(false);
            })
        } else if(data.role === 'vendor'){
            get(config.service+'/masters/base/vendor/',{headers:tokenKey}).then(res=>{
                let vendor =[];
                res.data.map(obj=>{
                    vendor.push({value:obj.id,label:obj.vendor_name});
                })
                setvendorList(vendor);
                get(config.service+'/masters/base/vendor-mandap/?&vendor_id='+data.vendor_id,{headers:tokenKey}).then(res=>{
                  let mandap =[];
                  res.data.map(obj=>{
                      mandap.push({value:obj.id,label:obj.mandap_name});
                  })
                  setMandapList(mandap);
                  let vendorVal = user.filter(temp=>temp.value === data.vendor_id);
                  let mandapVal = user.filter(temp=>temp.value === data.mandap_id);
                  setVendor(vendorVal);
                  setMandap(mandapVal);
                  setLoader(false);
                })
              })
        } 
      }).catch(err=>{
        if(err.response.status === 401) window.location.href = '/'
        setLoader(false);
      })
    } else setLoader(false)

  }, []);


  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
          <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv'>
            <Col md={12}>
                    <Button variant='primary' onClick={e=>{saveForm()}}>Save</Button> 
                    <Button variant='secondary' onClick={e=>{renderListView()}}>Cancel</Button>
            </Col>
        </Row>
        <Row className='formDiv'>
            <Col md={12}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                            {tabData.map((tab,index)=>{
                                let disCls = tab.disable;
                                return tab.show ? (
                                    <a className={`nav-item nav-link active`} 
                                    id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'>
                                        {tab.name}
                                    </a>
                                ) : '';
                            })}
                        </div>
                    </div>
                </nav>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                    <Loader loading={loading} overlay={overlay}>
                        {tab.details ? 
                        <Row><Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                        <Row>
                                                <Col md={4}>
                                                    <Form.Label>Role</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Select className={`selectBox ${!! errors.role  ? 'inValidField' : ''}`}
                                                    value={role}
                                                    onChange={e=>handleSelect('role',setRole,e)}
                                                    options={roleList}
                                                    isClearable
                                                />
                                                {errors.role ? 
                                                  <Form.Control.Feedback type='invalid' className='d-block'>
                                                      { errors.role }
                                                  </Form.Control.Feedback> : ''}
                                                </Col>
                                            </Row>
                                            {!empty(role) && role.value === 'agent' ? 
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>User</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Select className={`selectBox ${!! errors.user  ? 'inValidField' : ''}`}
                                                    value={user}
                                                    onChange={e=>handleSelect('user',setUser,e)}
                                                    options={userList}
                                                    isClearable
                                                />
                                                {errors.user ? 
                                                <Form.Control.Feedback type='invalid' className='d-block'>
                                                    { errors.user }
                                                </Form.Control.Feedback> : ''}
                                                </Col>
                                            </Row> : ''
                                            }

                                            {!empty(role) && role.value === 'vendor' ? 
                                            <>
                                            <Row>
                                                <Col md={4}>
                                                    <label>Vendor</label>
                                                </Col>
                                                <Col md={7}>
                                                <Select className={`selectBox ${!! errors.vendor  ? 'inValidField' : ''}`}
                                                    value={vendor}
                                                    onChange={e=>handleSelect('vendor',setVendor,e)}
                                                    options={vendorList}
                                                    isClearable
                                                />
                                                {errors.vendor ? 
                                                  <Form.Control.Feedback type='invalid' className='d-block'>
                                                      { errors.vendor }
                                                  </Form.Control.Feedback> : ''}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Mandap</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Select className={`selectBox ${!! errors.mandap  ? 'inValidField' : ''}`}
                                                    value={mandap}
                                                    onChange={e=>handleSelect('mandap',setMandap,e)}
                                                    options={mandapList}
                                                    isClearable
                                                />
                                                {errors.mandap ? 
                                                  <Form.Control.Feedback type='invalid' className='d-block'>
                                                      { errors.mandap }
                                                  </Form.Control.Feedback> : ''}
                                                </Col>
                                            </Row>
                                             </>: ''}
                                             <Row>
                                                <Col md={4}>
                                                    <Form.Label>Target Month</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <DatePicker
                                                    selected={targetMonth}
                                                    onChange={(date) => setTargetMonth(date)}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Target</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='integer' name="target" value={form.taget} onChange={e=> handleInput('target',e.target.value)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                        </Col></Row>
                        : ''}
                    </Loader>
                    </Form>
                </div>
            </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default TargetForm;

import React, { useState } from "react";
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import {useDropzone} from 'react-dropzone';
import Select from 'react-select'
import {HiCheckCircle} from 'react-icons/hi'
import { MultiSelect } from "react-multi-select-component";

const BulkUpdate = props => {
    const [mandap, setMandap] = useState([]);
    const [file, setFile] = useState(null);
    const [form, setForm] = useState({})
    const [mandapIds, setmandapIds] = useState({})
    const {
        acceptedFiles,
        getRootProps,
        getInputProps
      } = useDropzone({
        accept: {
          'text/html': ['.xls','.xlsx'],
        },
        onDrop : acceptedFiles => {
          setFile(acceptedFiles && acceptedFiles[0] || null);
          props.save({file:acceptedFiles && acceptedFiles[0] || null, mandap_list : mandapIds})
        }
      });


    const handleSelect = (keyName,setFn,e) =>{
        let ids = [];
        if(e) e.map(obj=>ids.push(obj.value))
        setmandapIds(ids)
          setFn(e);
          setForm({
              ...form,
              [keyName] : e  || null
          })
          props.save({file:file , mandap_list : ids})

      }
    

    return(
        <>
        <Row><Col md={12}>
            <Row>
                <Col md={10}>
                    <Row>
                        <Col md={2}>
                            <label>Mandap Name</label>
                        </Col>
                        <Col md={7}>
                            <MultiSelect
                                options={props.mandapList}
                                value={mandap}
                                onChange={e=>handleSelect('mandap_id',setMandap,e)}
                                labelledBy="Select"
                            />
                        {/* <Select className='selectBox' isMulti
                            value={mandap}
                            onChange={e=>handleSelect('mandap_id',setMandap,e)}
                            options={props.mandapList}
                            isClearable
                        /> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
    </Col></Row>
    <Row>
        <Col md={1}></Col>
        <Col md={6}>
            <section className="container file-dropzone-container">
            <div {...getRootProps({ className: 'dropzone' })} multiple={false}>
            <input {...getInputProps()} />
            <p>{file ? <>{file.name +' is uploaded'} <HiCheckCircle size={20} color='green' /></> : "Drag 'n' Drop or click here to add file"}</p>
            </div>
        </section>
        </Col>
    </Row>
    </>

    )
}

export default BulkUpdate;
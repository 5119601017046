import React,{useState,useEffect} from 'react';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config';
import Loader from '../../../Helper/Loader'
import empty from 'is-empty'
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle,Select,MenuItem,FormControl,InputLabel,TextareaAutosize} from '@mui/material'


const ChangePassword = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};

  const [form, setForm] = useState(props.data || {});
  const [editMode, seteditMode] = useState(props.data ? true : false);
  const [errors, setErrors] = useState({});
  const [openDialog, setopenDialog] = useState(true);
  const [loading, setloading] = useState(false);
  const [overlay, setoverlay] = useState(false);
  const [mandapList, setMandapList] = useState(props.mandapList || []);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };

    // useEffect(() => {
    //     get(config.service+'/masters/base/vendor-mandap/',{headers:tokenKey}).then(res=>{
    //         let mandap =[];
    //         res.data.map(obj=>{
    //             mandap.push({id:obj.id,name:obj.mandap_name});
    //         })
    //         setMandapList(mandap);
    //         setLoader(false);
    //       }).catch(err=>{
    //         if(err.response.status === 401) window.location.href = '/'
    //         setLoader(false);
    //       })
    // }, []);

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


  const findErrors = () => {
    const { mandap,heading,content } = form
    const newErrors = {}
    if ( empty(mandap) ) newErrors.mandap = 'This is a required field';
    if ( empty(heading) ) newErrors.heading = 'This is a required field';
    if ( empty(content) ) newErrors.content = 'This is a required field';

    return newErrors;
   }

  const saveForm = () => {
    const newErrors = findErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors)
      toast.error('Enter all the details to proceed', toastAttr);
    } else {
      setLoader(true);
        let data=JSON.parse(JSON.stringify(form));
        axios({
            url: editMode ? config.service+'/masters/blog/'+form.id+'/' : config.service+'/masters/blog/',
            method: editMode ? 'put' : 'post',
            data: data,
            headers: {Authorization:'Token '+sessionStorage.getItem('tokenKey')},
          }).then(res=>{
              setLoader(false);
              if(editMode) toast.success('Blog content updated', toastAttr);
              else toast.success('Blog content added', toastAttr);
              props.handleClose()
        }).catch(err=>{
          if(err.response.status === 401) window.location.href = "/"
          else
            toast.error('Failed', toastAttr);
            setLoader(false);
        })
    }
  }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }

  const handleDialog = () => {
    setopenDialog(!openDialog);
  }


  return (
    <>
    <Dialog open={!loading} onClose={()=>props.handleClose(true)} maxWidth='lg'>
        <DialogTitle>Blog Details</DialogTitle>
        <DialogContent>
        {/* <Loader loading={loading} overlay={overlay}> */}
            <FormControl variant="standard" sx={{  minWidth: '100%' }} className='mar-t-5'>
                <InputLabel id='label1'>Mandapam</InputLabel>
                <Select required labelId='label1' className='select-type'
                    value={form.mandap || ''}
                    onChange={e=> handleInput('mandap',e.target.value)}
                    label="Mandapam *"
                    fullWidth
                    variant="standard">
                    {mandapList.map(type=>{
                        return <MenuItem value={type.id} className='select-item'>{type.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
          <TextField value={form.heading}
          onChange={e=> handleInput('heading',e.target.value)}
             required
            margin="dense"
            id="heading"
            label="Blog heading"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextareaAutosize value={form.content}
          onChange={e=> handleInput('content',e.target.value)}
             required minRows={6} className='blogContent'
            margin="dense"
            id="content"
            placeholder="Blog content *"
            type="text"
            fullWidth
            variant="standard"
          />
          {/* </Loader> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={saveForm}>Save</Button>
          <Button onClick={()=>props.handleClose(true)}>Cancel</Button>
        </DialogActions>
      </Dialog>

  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        </>
);
};

export default ChangePassword;

import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { HiOutlineTrash } from "react-icons/hi";
import Search from "../../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FiEdit2 } from "react-icons/fi";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import { saveAs } from "file-saver";

const RazorPayLogs = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "Razor Payment Logs",
      href: "#",
    },
  ];
  const [tableData, settableData] = useState([]);

  const renderForm = () => {
    fetch(config.service + "/razorPay_inte/all_razor_pay_order/", {
      method: "POST",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("tokenKey"),
        "Content-Type": "application/json",
        // 'X-CSRFToken': getCookie('csrftoken')
      },
    })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, "orders.xlsx"))
      .catch((error) => console.error(error));
  };
  const renderOnSearch = (searchTerm) => {
    get(
      config.service + "/razorPay_inte/get_all_orders/?&search=" + searchTerm,
      tokenKey
    )
      .then((res) => {
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("Search Failed", toastAttr);
      });
  };
  useEffect(() => {
    get(config.service + "/razorPay_inte/get_all_orders/", tokenKey)
      .then((res) => {
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
  }, []);

  let options = paginationFactory({
    sizePerPage: 10,
    paginationSize: 1,
    hideSizePerPage: true,
    totalSize: tableData.length,
  });

  const cols = [
    {
      dataField: "name",
      text: "Customer Name",
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "mobile",
      text: "Customer Mobile",
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "mandap",
      text: "Mandap",
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "city_name",
      text: "City Name",
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "provider_order_id",
      text: "RazorPay Order ID",
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "payment_id",
      text: "Razor Pay Payment ID",
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "amount",
      text: "Amount",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "booking_no",
      text: "Booking No",
    },
    {
      dataField: "failure_details.description",
      text: "Failure Details",
    },
  ];

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}>
                  <Button variant="primary" onClick={(e) => renderForm()}>
                    Download
                  </Button>
                </Col>
                <Col md={4}>
                  <Search callbackFn={(e) => renderOnSearch(e)} />
                </Col>
              </Row>

              <Row className="vendorList">
                <BootstrapTable
                  bootstrap4
                  remote
                  wrapperClasses="table-responsive pad-20"
                  data={tableData}
                  columns={cols}
                  keyField="id"
                  containerStyle={{ width: "100%" }}
                  hover
                  noDataIndication="No data found"
                  pagination={options}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default RazorPayLogs;

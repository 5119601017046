import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FaChartArea } from "react-icons/fa";
import { HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import Search from "../../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FiEdit2 } from "react-icons/fi";
import Breadcrumbs from "../../../Helper/Breadcrumbs";

const Post = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let propsState = props.location.state;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "Posts",
      href: "#",
    },
  ];
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const deleteRow = (row) => {
    var result = window.confirm("Are you want to delete?");

    if (result) {
      axios
        .delete(config.service + "/masters/post/" + row.id, tokenKey)
        .then((resp) => {
          get(config.service + "/masters/post/", tokenKey).then((res) => {
            settableData(res.data[0].data);
          });
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          // toast.error('Deletion Failed', toastAttr);
        });
    }
  };

  const renderForm = (row) => {
    if (row)
      history.push({
        pathname: "/masters/postForm",
        state: {
          id: row.id,
          // vendor_id : propsState.id,
          editMode: true,
        },
      });
    else
      history.push({
        pathname: "/masters/postForm",
        state: {
          // vendor_id : propsState.id,
        },
      });
  };
  const renderOnSearch = (searchTerm) => {
    get(config.service + "/masters/post/?&search=" + searchTerm, tokenKey)
      .then((res) => {
        settableData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("Search Failed", toastAttr);
      });
  };
  useEffect(() => {
    get(config.service + "/masters/post/", tokenKey)
      .then((res) => {
        let result = res.data[0].data;
        settableData(result);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
      });
  }, []);
  // const columns = [{
  //   dataField: 'name',
  //   text: 'Area Name'
  // }, {
  //   dataField: 'description',
  //   text: 'Description'
  // }];

  const cols = [
    {
      dataField: "name",
      text: "Name",
      headerStyle: () => {
        return { width: "250px" };
      },
      formatter: (cell, row) => {
        let val = row.name ? row.name : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "18px" }}>{val}</div>
        );
      },
    },
    {
      dataField: "description",
      text: "Description",
      headerStyle: () => {
        return { width: "550px" };
      },
      formatter: (cell, row) => {
        let val = row.description ? row.description : "";
        return (
          <div style={{ maxWidth: "500px", lineHeight: "18px" }}>{val}</div>
        );
      },
    },

    {
      dataField: "id",
      text: "Actions",
      headerStyle: () => {
        return { width: "120px" };
      },
      events: {
        onClick: (e) => {
          return e.stopPropagation();
        },
      },
      formatter: (cell, row) => {
        return (
          <div className="tableActions">
            <React.Fragment>
              <Link
                to={{
                  pathname: "/masters/postForm/",
                  state: { id: row.id, editMode: true },
                }}
              >
                <FiEdit2 title="Edit" />
              </Link>
              <HiOutlineTrash title="Delete" onClick={(e) => deleteRow(row)} />
            </React.Fragment>
          </div>
        );
      },
    },
  ];
  const rowEvents = {
    onClick: (e, row) => {
      history.push({
        pathname: "/masters/postForm",
        state: {
          id: row.id,
          editMode: true,
        },
      });
    },
  };
  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    totalSize: tableData.length,
    hidePageListOnlyOnePage: true,
  });
  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}>
                  <Button variant="primary" onClick={(e) => renderForm()}>
                    Create
                  </Button>
                  {/* <Button variant='primary' onClick={e=>setShowModal(true)}>View</Button> */}
                </Col>
                <Col md={4}>
                  {/* <Search callbackFn={e=>renderOnSearch(e)} /> */}
                </Col>
              </Row>
              <Row className="vendorList">
                {/* {tableData.length>0 ? tableData.map(row=>{
                      return (
                          <Col md={4} className='divCont'>
                              <div className='child' onClick={e=>renderForm(row)}>
                              <FaChartArea style={{color : '#00B0FC'}} className='vendorImg' />
                                </div>
                              <div className='child' onClick={e=>renderForm(row)}>
                                  <div className='header'> {row.name}</div>
                              </div>
                              <div className='child'>
                                  <HiPencilAlt className='color-green actionIcon' onClick={e=> renderForm(row)}/>
                                  <HiOutlineTrash className='themeColor actionIcon' onClick={e => deleteRow(row) } />
                              </div>
                          </Col>
                      )
                  }) : <div className='textCenter'>No data found</div>}

                  <Col md={6}></Col>
                  <BootstrapTable keyField='id' data={ tableData } columns={ columns } /> */}
                <BootstrapTable
                  bootstrap4
                  wrapperClasses="table-responsive pad-20"
                  data={tableData}
                  columns={cols}
                  keyField="id"
                  containerStyle={{ width: "100%" }}
                  hover
                  noDataIndication="No data found"
                  rowEvents={rowEvents}
                  pagination={options}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};
export default Post;
